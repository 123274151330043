import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import CheckboxComponent from '../commons/components/CheckboxComponent'
import RoundTagComponent from '../commons/components/RoundTagComponent'
import { IVersion } from '../models/IVersion'

export type VersionCardComponentProps = {
    version: IVersion
    onEdit: (version: IVersion) => void
    onDelete: (versionId: string | undefined) => void
    onCancelPublish: (version: IVersion) => void
    isVersionSelected: (versionId: string | undefined) => boolean
    onSelectVersion: (event: any) => void
}

export default function VersionCardComponent({
    version,
    onEdit,
    onDelete,
    onCancelPublish,
    isVersionSelected,
    onSelectVersion,
}: VersionCardComponentProps): JSX.Element {
    const isVersionPublished = (): boolean => Boolean(version.published)

    const getIconColor = () =>
        isVersionPublished() ? COLORS.disabledText : COLORS.primaryGray

    const renderVersionTag = (): JSX.Element => {
        if (!isVersionPublished()) {
            return (
                <TagContainer>
                    <RoundTagComponent
                        text="Aguardando"
                        color={COLORS.warning}
                    />
                </TagContainer>
            )
        }

        return (
            <VersionTagsContainer>
                <TagContainer>
                    <TagButton
                        data-testid="cancel-publish-button"
                        onClick={() => onCancelPublish(version)}
                    >
                        <TagText>Cancelar Publicação</TagText>
                    </TagButton>
                </TagContainer>
                <TagContainer>
                    <RoundTagComponent
                        text="Publicado"
                        color={COLORS.disabledButton}
                    />
                </TagContainer>
            </VersionTagsContainer>
        )
    }

    return (
        <Container
            published={version.published}
            data-testid="version-card"
            key={version.id}
        >
            <CardHeader>
                {!version.published && (
                    <CheckboxContainer>
                        <CheckboxComponent
                            checked={isVersionSelected(version.id)}
                            onChange={onSelectVersion}
                            value={version.id || ''}
                        />
                    </CheckboxContainer>
                )}
                <CardTitle>
                    <CardName>{version.source?.name}</CardName>
                    <VersionNumber>VERSÃO {version.name}</VersionNumber>
                </CardTitle>
            </CardHeader>
            <FeaturesContainer>
                {version.features.map((feature) => (
                    <FeatureChip key={feature.id}>
                        <FeatureChipText>{feature.name}</FeatureChipText>
                    </FeatureChip>
                ))}
            </FeaturesContainer>
            <CardFooter>
                {renderVersionTag()}
                <ActionButton
                    data-testid="edit-icon-button"
                    onClick={() => onEdit(version)}
                    disabled={isVersionPublished()}
                >
                    <EditIcon sx={{ color: `${getIconColor()}` }} />
                </ActionButton>
                <ActionButton
                    data-testid="delete-icon-button"
                    onClick={() => onDelete(version.id)}
                    disabled={isVersionPublished()}
                >
                    <DeleteIcon sx={{ color: `${getIconColor()}` }} />
                </ActionButton>
            </CardFooter>
        </Container>
    )
}

interface IContainerProps {
    published?: boolean
}

const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    border: 1px solid ${COLORS.borderGray};
    background-color: ${(props) =>
        props.published ? COLORS.borderGray : COLORS.primaryWhite};
    font-family: Roboto;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 24px 34px 24px 10px;
`

const CardHeader = styled.div`
    display: flex;
    align-items: flex-start;
`

const CardTitle = styled.div``

const CardName = styled.p`
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primaryGray};
`

const VersionNumber = styled.p`
    font-family: Roboto;
    font-size: 12px;
    color: ${COLORS.primaryGray};
`

const FeaturesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 5px;
    border: 1px solid;
    border-color: ${COLORS.disabledButton};
    background-color: ${COLORS.primaryWhite};
    border-radius: 3px;
`

const FeatureChip = styled.div`
    flex-grow: 0;
    border-radius: 3px;
    background-color: #e5e5ea;
    padding: 5px 12px;
`

const FeatureChipText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: left;
    color: #666;
    margin: 0px;
`

const CardFooter = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: center;
`

const ActionButton = styled.button<IContainerProps>`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    border: none;
    background-color: transparent;
`

const TagButton = styled.button<IContainerProps>`
    cursor: pointer;
    min-width: 68px;
    min-height: 19px;
    border-radius: 24px;
    border-width: none;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.error};
`

const TagText = styled.span`
    margin: 1px 0 0 0;
    font-family: 'Roboto';
    font-size: 10px;
    color: ${COLORS.primaryWhite};
    text-align: center;
`

const VersionTagsContainer = styled.div`
    display: flex;
`

const TagContainer = styled.div`
    padding-right: 10px;
`

const CheckboxContainer = styled.div`
    margin-right: 5px;
    margin-top: 5px;
`
