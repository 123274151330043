function VersionIcon(): JSX.Element {
    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.386 11.213V8L0 13.5 5.386 19v-3.213H11L11 11.213H5.386zM13.614 0 19 5.5 13.614 11V7.787H8l.001-4.574h5.613V0z"
                fill="#666"
            />
        </svg>
    )
}

export default VersionIcon
