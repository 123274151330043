import { Check as CheckIcon } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { styled as materialStyled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../commons/Colors'
import LoadingComponent from '../../../commons/components/LoadingComponent'
import { PermissionConfig } from '../../../models/PermissionConfig'

type FooterProps = {
    configItems: PermissionConfig[]
    initialConfigItems: PermissionConfig[]
    isSaving: boolean
    onSave: () => Promise<void>
    onCancel: (configs: PermissionConfig[]) => void
}

export default function Footer({
    configItems,
    initialConfigItems,
    isSaving,
    onSave,
    onCancel,
}: FooterProps) {
    const [initialConfigs, setInitialConfigs] = useState(new Set())

    const handleCancel = () => {
        onCancel(initialConfigItems)
    }

    const handleValidateChanges = (): boolean => {
        return (
            configItems.every(({ item, active }) =>
                initialConfigs.has(`${active}-${item}`)
            ) && initialConfigs.size === configItems.length
        )
    }

    useEffect(() => {
        const newConfigSet = new Set()

        initialConfigItems.forEach((config) => {
            newConfigSet.add(`${config.active}-${config.item}`)
        })

        setInitialConfigs(newConfigSet)
    }, [initialConfigItems])

    return (
        <MainContainer>
            <Text>
                *Para habilitar as alterações de
                <TextBold> Customização por perfil de cliente</TextBold> é
                preciso SALVAR cada agrupamento de forma individual.
            </Text>
            <ButtonContainer>
                <CancelButton
                    data-testid="CancelButton"
                    variant="outlined"
                    onClick={handleCancel}
                >
                    Cancelar
                </CancelButton>
                <SaveButton
                    startIcon={isSaving && <LoadingComponent size="small" />}
                    data-testid="SaveButton"
                    onClick={onSave}
                    disabled={isSaving}
                    endIcon={<CheckIcon />}
                >
                    {handleValidateChanges() ? 'Salvar ' : '*Salvar '}
                </SaveButton>
            </ButtonContainer>
        </MainContainer>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 225px;
`

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
    backgroundColor: COLORS.primaryWhite,
    border: `1px solid ${COLORS.primaryOrange}`,
    color: COLORS.primaryOrange,
    borderRadius: '24px',
    padding: '4px 25px 4px 26px',
    marginRight: '10px',
})

const MainContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 25px;
    width: 97%;
`

const SaveButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    backgroundColor: COLORS.primaryGreen,
    borderColor: COLORS.primaryGreen,
    borderRadius: '24px',
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    padding: '4px 20px 4px 31.5px',
    '&:hover': {
        backgroundColor: COLORS.secondaryGreen,
        borderColor: COLORS.secondaryGreen,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

const Text = styled.span`
    color: ${COLORS.primaryGray};
    font-size: 12px;
`

const TextBold = styled.strong``
