import { Snackbar, Alert } from '@mui/material/'

import GlobalThemeProvider from '../../style/GlobalTheme'
import { SeverityTypes } from '../utils/Types'

export type SnackBarComponentProps = {
    severity: SeverityTypes
    isVisible: boolean
    showTimeInMs?: number
    message: string
    handleClose: () => void
}

function SnackBarComponent({
    severity,
    isVisible,
    showTimeInMs = 5000,
    message,
    handleClose,
}: SnackBarComponentProps): JSX.Element {
    return (
        <GlobalThemeProvider>
            <Snackbar
                open={isVisible}
                autoHideDuration={showTimeInMs}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                >
                    {message}
                </Alert>
            </Snackbar>
        </GlobalThemeProvider>
    )
}

export default SnackBarComponent
