import { getBaseURL, getAPIKey, getOrigin } from '../../environment'
import { IFeature } from '../../models/IFeature'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const endpoint = '/news/features'
const origin = getOrigin()

export const getAllFeatures = (): Promise<{ data: IFeature[] }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createFeature = (
    feature: IFeature
): Promise<{ data: IFeature[] }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(feature),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const updateFeature = (
    feature: IFeature
): Promise<{ data: IFeature[] }> => {
    return fetch(`${baseURL}${endpoint}/${feature.id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(feature),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getFeatureById = (
    featureId: string
): Promise<{ data: IFeature[] }> => {
    return fetch(`${baseURL}${endpoint}/${featureId}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const deleteFeature = (
    featureId: string
): Promise<{ message: string }> => {
    return fetch(`${baseURL}${endpoint}/${featureId}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
