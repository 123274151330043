import {
    ILocalStorageService,
    StorageKeys,
} from '../../models/ILocalStorageService'

export function makeLocalStorageService(): ILocalStorageService {
    return {
        getItem: <T>(key: StorageKeys): T => {
            const item = localStorage.getItem(key.toString())
            if (!item) return undefined as unknown as T
            return JSON.parse(item) as T
        },

        setItem: (key: StorageKeys, item: any): void => {
            return localStorage.setItem(key.toString(), JSON.stringify(item))
        },

        removeItem: (key: StorageKeys): void => {
            return localStorage.removeItem(key.toString())
        },
    }
}
