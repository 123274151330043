import { useCallback, useEffect, useState } from 'react'

import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { Mill } from '../../models/Mill'
import * as ClientAPI from '../API/ClientAPI'
import * as ImportAPI from '../API/ImportAPI'
import {
    ImportConfirmation,
    ImportForm,
    ImportValidation,
    importValidationInitialValue,
    importFormInitialValue,
    importConfirmationInitialValue,
} from '../utils'
import ImportView from './ImportView'

function ImportContainer(): JSX.Element {
    // TODO: Refatorar todo o estado do formulário em um reducer
    const [importForm, setImportForm] = useState<ImportForm>(
        importFormInitialValue
    )
    const [importValidation, setImportValidation] = useState<ImportValidation>(
        importValidationInitialValue
    )
    const [mills, setMills] = useState<Mill[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [importConfirmation, setImportConfirmation] =
        useState<ImportConfirmation>({
            isModalVisible: false,
            isProcessing: false,
        })
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })

    function mountMillOptions(mills: Mill[]) {
        return mills.map((mill) => ({
            label: mill.name,
            value: mill.id,
        }))
    }

    useEffect(() => {
        async function fetchMills(): Promise<void> {
            const response = await ClientAPI.getAllMills()

            if (response.data) {
                setMills(response.data)
                setIsLoading(false)
            }
        }

        try {
            fetchMills()
        } catch (err) {
            console.error(err)
        }
    }, [])

    const isXlsxFile = (type: string): boolean => {
        return (
            type === 'application/vnd.ms-excel' ||
            type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
    }

    const handleImportFileValidation = async (
        field: 'importFile',
        file: any
    ): Promise<void> => {
        setImportForm((prev) => ({ ...prev, [field]: file }))
        setImportValidation((prev) => ({
            ...prev,
            isProcessing: true,
            isDone: false,
        }))

        if (!isXlsxFile(file.type)) {
            setImportValidation((prev) => ({
                ...prev,
                errors: [],
                isProcessing: false,
            }))

            setImportForm((prev) => ({ ...prev, importFile: null }))

            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message:
                    'Formato de Arquivo inválido, selecione um arquivo ".xlsx"',
                status: 'error',
            }))
            return
        }

        try {
            const response = await ImportAPI.validateImportFile({
                millId: importForm.mill.id!,
                importType: importForm.importType,
                file,
            })

            if (response && response.data.length) {
                setImportValidation((prev) => ({
                    ...prev,
                    isProcessing: false,
                    errors: response.data,
                    isDone: true,
                }))
                setSnackBarConfig((prev) => ({
                    ...prev,
                    isVisible: true,
                    message: `Um ou mais erros encontrados no arquivo: ${file.name}.`,
                    status: 'error',
                }))
                return
            }

            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Arquivo "${file.name}" validado com sucesso.`,
                status: 'success',
            }))
            setImportValidation((prev) => ({
                ...prev,
                errors: [],
                isProcessing: false,
                isDone: true,
            }))
        } catch (err) {
            setImportValidation((prev) => ({
                ...prev,
                isProcessing: false,
                errors: [],
                isDone: false,
            }))
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Ocorreu um erro durante a importação. Consulte a equipe técnica para mais informações.`,
                status: 'error',
            }))
            const error = err as Error
            console.error(error?.message)
        }
    }

    const handleForm = useCallback(
        async (field: keyof ImportForm, value?: any, label?: string) => {
            if (!value) return

            if (field === 'importFile') {
                await handleImportFileValidation(field, value)
                return
            }

            if (field === 'importType') {
                setImportForm((prev) => ({
                    ...prev,
                    [field]: value,
                }))
                return
            }

            setImportForm((prev) => ({
                ...prev,
                [field]: { name: label!, id: value },
            }))
        },
        [importForm]
    )

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    const handleCloseConfirmationModal = () => {
        if (importConfirmation.isProcessing) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message:
                    'Importação em processamento, por favor aguarde a conclusão da operação.',
                status: 'info',
            }))
            return
        }

        setImportConfirmation((prev) => ({ ...prev, isModalVisible: false }))
    }

    const handleImportConfirmation = async (isSendFile = false) => {
        if (!isSendFile) {
            setImportConfirmation((prev) => ({ ...prev, isModalVisible: true }))
            return
        }

        setImportConfirmation((prev) => ({ ...prev, isProcessing: true }))

        try {
            await ImportAPI.sendImportFile({
                millId: importForm.mill.id!,
                importType: importForm.importType,
                file: importForm.importFile,
            })

            setImportConfirmation(importConfirmationInitialValue)
            setImportValidation(importValidationInitialValue)
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Arquivo "${importForm.importFile.name}" importado com sucesso.`,
                status: 'success',
            }))
            setImportForm(importFormInitialValue)
        } catch (err) {
            setImportConfirmation((prev) => ({ ...prev, isProcessing: false }))
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message:
                    'Ocorreu um erro durante a importação. Consulte a equipe técnica para mais informações.',
                status: 'error',
            }))
            const error = err as Error
            console.error(error?.message)
        }
    }

    const isFileUploadEnabled = () => {
        return Boolean(importForm?.mill.id && importForm?.importType.value)
    }

    const isValidationError = () => {
        return (
            importValidation.isDone && Boolean(importValidation.errors.length)
        )
    }

    const isConfirmEnabled = () => {
        return (
            isFileUploadEnabled() &&
            importValidation.isDone &&
            !importValidation.errors.length &&
            Boolean(importForm.importFile)
        )
    }

    return (
        <ImportView
            isLoading={isLoading}
            isConfirmEnabled={isConfirmEnabled()}
            isFileUploadEnabled={isFileUploadEnabled()}
            isValidationError={isValidationError()}
            importForm={importForm}
            handleForm={handleForm}
            importValidation={importValidation}
            importConfirmation={importConfirmation}
            handleImportConfirmation={handleImportConfirmation}
            handleCloseConfirmationModal={handleCloseConfirmationModal}
            snackbarConfig={snackbarConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            mills={mountMillOptions(mills)}
        />
    )
}

export default ImportContainer
