import { getBaseURL } from '../../environment'
import { TokenFabs } from '../../models/Auth'

const baseURL = getBaseURL()
const endpoint = '/auth/login'

export type LoginPayload = {
    login: string
    password: string
}

export async function signIn({
    login,
    password,
}: LoginPayload): Promise<TokenFabs> {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            user: login,
            password,
        }),
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error(`Erro: ${JSON.stringify(error)}`)
            throw error
        })
}
