import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import { useState } from 'react'

import { COLORS } from '../Colors'

type FilterComponentProps = {
    placeHolder: string
    onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function FilterComponent({
    placeHolder,
    onChange,
}: FilterComponentProps): JSX.Element {
    const [containerOnFocus, setContainerOnFocus] = useState(false)

    return (
        <Paper
            component="form"
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                boxShadow: containerOnFocus ? 1 : 0,
                border: 1,
                transition: 'box-shadow 0.5s',
                borderColor: COLORS.borderGray,
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1, fontSize: 14 }}
                placeholder={placeHolder}
                onChange={onChange}
                onFocus={() => setContainerOnFocus(true)}
                onBlur={() => setContainerOnFocus(false)}
            />

            <SearchIcon sx={{ color: COLORS.primaryGray }} />
        </Paper>
    )
}
