import { Radio } from '@mui/material'
import styled from 'styled-components'

import GlobalThemeProvider from '../../style/GlobalTheme'

export type RadioButtonComponentProps = {
    inputName: string
    value: any
    selectedValue: any
    onChange: (name: string, value: string) => void | null
}

function RadioButtonComponent({
    inputName,
    value,
    selectedValue,
    onChange,
}: RadioButtonComponentProps): JSX.Element {
    return (
        <GlobalThemeProvider>
            <ComponentContainer>
                <RadioInput
                    checked={selectedValue === value}
                    id={`id-${value}`}
                    name={inputName}
                    value={value}
                    onChange={({ target }) =>
                        onChange(target.value, target.name)
                    }
                />
            </ComponentContainer>
        </GlobalThemeProvider>
    )
}

const RadioInput = styled(Radio)``
const ComponentContainer = styled.div``

export default RadioButtonComponent
