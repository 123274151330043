import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'

import CloseCrossIcon from '../../assets/icons/SVG/CloseCrossIcon'
import { COLORS } from '../Colors'

export type ModalComponentProps = {
    onClose: () => void | null
    isVisible: boolean
    title: string
    children: JSX.Element | React.ReactNode
}

function ModalComponent({
    onClose,
    isVisible,
    title,
    children,
}: ModalComponentProps): JSX.Element {
    return (
        <Dialog open={isVisible} fullWidth>
            <ModalContainer>
                <ModalHeaderContainer>
                    <ModalHeaderTitleContainer>
                        <ModalHeaderTitle>{title}</ModalHeaderTitle>
                    </ModalHeaderTitleContainer>
                    <ModalHeaderCloseButtonContainer>
                        <ModalHeaderCloseButton onClick={onClose}>
                            <CloseCrossIcon />
                        </ModalHeaderCloseButton>
                    </ModalHeaderCloseButtonContainer>
                </ModalHeaderContainer>
                {children}
            </ModalContainer>
        </Dialog>
    )
}

const ModalContainer = styled.div`
    background-color: ${COLORS.primaryWhite};
`
const ModalHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${COLORS.borderGray};
`
const ModalHeaderTitleContainer = styled.div`
    padding: 32px 0 32px 24px;
`
const ModalHeaderTitle = styled.span`
    color: ${COLORS.primaryOrange};
    font-weight: bold;
    font-family: Roboto;
    font-size: 18px;
`
const ModalHeaderCloseButtonContainer = styled.div`
    padding: 5px 0px 0 0;
`

const ModalHeaderCloseButton = styled.button`
    background-color: ${COLORS.primaryWhite};
    border: none;
    color: ${COLORS.secondaryGray};
    cursor: pointer;
`

export default ModalComponent
