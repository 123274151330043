import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import RadioButtonComponent from '../commons/components/RadioButtonComponent'

type OptionsTypes = {
    value: { id: string; [key: string]: any }
    label: string
}

export type SourceOptionsListComponentProps = {
    inputTitle: string
    inputName: string
    options: OptionsTypes[] | []
    selectedValue: any
    onChange: (name: string, value: string) => void | null
}

function SourceOptionsListComponent({
    inputTitle,
    inputName,
    selectedValue,
    options,
    onChange,
}: SourceOptionsListComponentProps): JSX.Element {
    return (
        <ComponentContainer>
            <InputTitleContainer>
                <InputTitle>{inputTitle}</InputTitle>
            </InputTitleContainer>
            <OptionsContainer>
                {options.map((option) => (
                    <RadioButtonContainer key={`radio-${option.label}`}>
                        <RadioButtonComponent
                            inputName={inputName}
                            value={option.value.id}
                            selectedValue={selectedValue}
                            onChange={onChange}
                        />
                        <InputLabel htmlFor={`id-${option.value.id}`}>
                            {option.label}
                        </InputLabel>
                    </RadioButtonContainer>
                ))}
            </OptionsContainer>
        </ComponentContainer>
    )
}

const ComponentContainer = styled.div``
const InputTitleContainer = styled.div``
const InputTitle = styled.span`
    text-transform: uppercase;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primaryGray};
    line-height: 2;
`

const OptionsContainer = styled.div`
    display: flex;
`

const RadioButtonContainer = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
`

const InputLabel = styled.label`
    font-family: Roboto;
    font-size: 14px;
    color: ${COLORS.primaryGray};
    line-height: 2;
`

export default SourceOptionsListComponent
