import { useState } from 'react'
import styled from 'styled-components'

import PaginationComponent, {
    getPaginatedData,
} from '../commons/components/PaginationComponent'
import { IVersion } from '../models/IVersion'
import VersionCardComponent from './VersionCardComponent'

export type VersionCardListComponentProps = {
    versions: IVersion[]
    onEdit: (version: IVersion) => void
    onDelete: (versionId: string | undefined) => void
    onCancelPublish: (version: IVersion) => void
    isVersionSelected: (versionId: string | undefined) => boolean
    onSelectVersion: (event: any) => void
}

export default function VersionCardListComponent({
    versions,
    onEdit,
    onDelete,
    onCancelPublish,
    isVersionSelected,
    onSelectVersion,
}: VersionCardListComponentProps): JSX.Element {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const pagination = 4

    const handleOnChangePage = (
        _event: React.ChangeEvent<unknown>,
        page: number
    ): void => {
        setCurrentPage(page)
    }

    const renderPaginatedVersions = (): JSX.Element[] => {
        const paginatedVersions = getPaginatedData(
            versions,
            pagination,
            currentPage
        )

        if (versions.length && !paginatedVersions.length) setCurrentPage(1)

        return paginatedVersions.map((version) => (
            <CardContainer key={version.id}>
                <VersionCardComponent
                    version={version}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onCancelPublish={onCancelPublish}
                    isVersionSelected={isVersionSelected}
                    onSelectVersion={onSelectVersion}
                />
            </CardContainer>
        ))
    }

    return (
        <>
            <CardListContainer>{renderPaginatedVersions()}</CardListContainer>
            <PaginationContainer>
                <PaginationComponent
                    numberOfPages={versions.length / pagination}
                    onChange={handleOnChangePage}
                    currentPage={currentPage}
                />
            </PaginationContainer>
        </>
    )
}

const CardListContainer = styled.div``

const CardContainer = styled.div`
    padding: 13px 23px 8px 22px;
`
const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 32px 0 32px 0;
`
