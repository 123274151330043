import { makeLocalStorageService } from './commons/utils/LocalStorageService'
import { TokenFabs } from './models/Auth'
import { StorageKeys } from './models/ILocalStorageService'

const BASE_URL = {
    PRD: 'https://api.prodapfabs.com.br',
    HOM: 'https://fabs-qa-d2d2.ue.r.appspot.com',
    DEV: 'https://fabs-dev-c61d.ue.r.appspot.com',
}
// TODO - Adicionar lógica para PROD x DEV

export const getBaseURL = (): string => {
    if (process.env.REACT_APP_NODE_ENV === 'production') return BASE_URL.PRD

    if (process.env.REACT_APP_NODE_ENV === 'qa') return BASE_URL.HOM

    return BASE_URL.DEV
}

export const getAPIKey = (): string => '44d73d93-0831-473c-96d6-c85d41cf950a'

export const getOrigin = (): string => 'fabs-web-management'

const { getItem } = makeLocalStorageService()
export const getToken = ({ withPayload } = { withPayload: false }) => {
    const token: TokenFabs = getItem(StorageKeys.TOKEN)

    if (token && token.accessToken) {
        return withPayload ? token.accessToken : token.accessToken.token
    }

    return null
}
