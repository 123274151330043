import { useEffect, useState } from 'react'

import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { BillingDateConfig } from '../../models/BillingDateConfig'
import { getBilingDateConfig, setBilingDateConfig } from '../AppPermissionsAPI'
import BillingDateConfigView from './BillingDateConfigView'

export default function BillingDateConfigContainer() {
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(true)
    const [isSavingConfig, setIsSavingConfig] = useState<boolean>(false)
    const [config, setConfig] = useState<BillingDateConfig>({
        acceptImmediateOrders: true,
        currentMonth: {
            start: 1,
            end: 15,
        },
        nextMonths: {
            start: 1,
            end: 15,
        },
    })

    useEffect(() => {
        loadConfig()
    }, [])

    async function loadConfig() {
        try {
            const { data } = await getBilingDateConfig()
            if (data) setConfig(data)
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Erro ao buscar configuração de datas, por favor tente mais tarde!`,
                status: 'error',
            }))
            console.log(error)
        } finally {
            setIsLoadingConfig(false)
        }
    }

    const handleSaveConfig = async () => {
        try {
            setIsSavingConfig(true)
            await setBilingDateConfig(config)
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Configuração salva com sucesso.`,
                status: 'success',
            }))
        } catch (error) {
            setSnackBarConfig((prev) => ({
                ...prev,
                isVisible: true,
                message: `Ocorreu um erro ao tentar salvar a configuração.`,
                status: 'error',
            }))
        } finally {
            setIsSavingConfig(false)
        }
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    const handleAcceptImmediate = (acceptImmediateOrders: boolean) => {
        setConfig((crr) => ({ ...crr, acceptImmediateOrders }))
    }

    const handleConfigDay = ({ month, period, value }: HandleConfigDay) => {
        setConfig((crr) => {
            const newConfig = crr[month]
            newConfig[period] = value
            return { ...crr, [month]: newConfig }
        })
    }
    return (
        <BillingDateConfigView
            billingDateConfig={config}
            handleAcceptImmediate={handleAcceptImmediate}
            handleSaveConfig={handleSaveConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            snackbarConfig={snackbarConfig}
            handleConfigDay={handleConfigDay}
            isLoadingConfig={isLoadingConfig}
            isSavingConfig={isSavingConfig}
        />
    )
}

export type HandleConfigDay = {
    month: 'currentMonth' | 'nextMonths'
    period: 'start' | 'end'
    value: number
}
