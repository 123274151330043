import { Checkbox as muiCheckbox } from '@mui/material'
import styled from 'styled-components'

import { COLORS } from '../Colors'

type CheckboxProps = {
    checked: boolean
    onChange: (event: any) => void
    value: string
}

export default function CheckboxComponent({
    checked,
    onChange,
    value,
}: CheckboxProps): JSX.Element {
    return (
        <BasicCheckbox
            checked={checked}
            onChange={onChange}
            value={value}
            sx={{ padding: 0 }}
        />
    )
}

const BasicCheckbox = styled(muiCheckbox)`
    & > svg {
        color: ${(props) =>
            props.checked ? COLORS.primaryOrange : COLORS.lightBlueGrey};
    }
`
