import {
    Configuration,
    PermissionConfig,
} from '../../../models/PermissionConfig'

const getGeneralConfigSet = (configList: Configuration[]) => {
    const generalConfigSet = new Set<string>()

    configList
        .filter((config) => config.option === 'all')
        .forEach(({ list }) => {
            list.forEach(({ active, item }) => {
                generalConfigSet.add(`${active}-${item}`)
            })
        })

    return generalConfigSet
}

const getExistentConfigOptions = (configList: Configuration[]) =>
    configList.map(({ option }) => option)

const overwriteGeneralConfigurationForSpecific = (
    option: string,
    configItems: PermissionConfig[],
    generalConfigSet: Set<string>
) => {
    let newConfigItems = configItems

    if (option !== 'all')
        newConfigItems = configItems.filter(
            ({ active, item }) => !generalConfigSet.has(`${active}-${item}`)
        )

    return newConfigItems
}

const getGeneralConfigItems = (configList: Configuration[]) => {
    const [{ list } = { list: [] }] = configList.filter(
        (config) => config.option === 'all'
    )

    return list
}

const getSpecificConfigItems = (
    configList: Configuration[],
    option: string
) => {
    const [{ list } = { list: [] }] = configList.filter(
        (config) => config.option === option
    )

    return list
}

const groupSpecificConfigItemsWithGeneralConfigItems = (
    specificConfigItems: PermissionConfig[],
    generalConfigItems: PermissionConfig[]
) => {
    const specificConfigSet = new Set<string>()

    specificConfigItems.forEach(({ item }) => {
        specificConfigSet.add(item)
    })

    generalConfigItems.forEach((config) => {
        if (!specificConfigSet.has(config.item))
            specificConfigItems.push(config)
    })

    return specificConfigItems
}

const generateNewConfigList = (
    option: string,
    existentConfigOptions: string[],
    configList: Configuration[],
    newConfigItems: PermissionConfig[]
) => {
    let newConfigList

    if (existentConfigOptions.includes(option)) {
        newConfigList = configList.map((config) => {
            if (config.option === option) {
                return {
                    option,
                    list: newConfigItems,
                }
            }
            return config
        })
    } else {
        newConfigList = [...configList, { option, list: newConfigItems }]
    }

    return newConfigList
}

export const formatNewConfigList = (
    option: string,
    configList: Configuration[],
    configItems: PermissionConfig[]
) => {
    const generalConfigSet = getGeneralConfigSet(configList)
    const existentConfigOptions = getExistentConfigOptions(configList)
    const newConfigItems = overwriteGeneralConfigurationForSpecific(
        option,
        configItems,
        generalConfigSet
    )
    const newConfigList = generateNewConfigList(
        option,
        existentConfigOptions,
        configList,
        newConfigItems
    )

    return newConfigList
}

export const formatConfigItems = (
    option: string,
    configList: Configuration[]
) => {
    const generalConfigItems = getGeneralConfigItems(configList)

    if (option === 'all') return generalConfigItems

    const specificConfigItems = getSpecificConfigItems(configList, option)
    const groupingConfigItems = groupSpecificConfigItemsWithGeneralConfigItems(
        specificConfigItems,
        generalConfigItems
    )

    return groupingConfigItems
}
