import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import RoundTagComponent from '../commons/components/RoundTagComponent'
import { IFeature } from '../models/IFeature'
import { isDraftFeature } from './utils/FeatureUtils'

export type FeatureCardComponentProps = {
    feature: IFeature
    onEdit: (feature: IFeature) => void
    onDelete: (feature: IFeature) => void
}

function FeatureCardComponent({
    feature,
    onEdit,
    onDelete,
}: FeatureCardComponentProps): JSX.Element {
    const isFeaturePublished = (): boolean => Boolean(feature.versionId)

    const isAppSource = (): boolean => feature?.source?.name === 'App'

    const renderSourceIcon = (): JSX.Element => {
        if (isAppSource()) {
            return (
                <RoundTagComponent
                    text="App"
                    color={COLORS.primaryBlue}
                    small
                />
            )
        }

        return (
            <RoundTagComponent
                text="Portal"
                color={COLORS.portalRedTag}
                small
            />
        )
    }

    const renderStatusIcon = (): JSX.Element => {
        if (isDraftFeature(feature)) {
            return (
                <RoundTagComponent
                    text="Rascunho"
                    color={`${COLORS.warning}`}
                />
            )
        }

        if (isFeaturePublished()) {
            return (
                <RoundTagComponent
                    text="Publicado"
                    color={`${COLORS.disabledButton}`}
                />
            )
        }

        return <RoundTagComponent text="Salvo" color={`${COLORS.success}`} />
    }

    const getIconColor = () =>
        isFeaturePublished() ? COLORS.disabledText : COLORS.primaryGray

    return (
        <CardContainer
            published={isFeaturePublished()}
            data-testid="feature-card"
        >
            <CardHeader>
                <FeatureName>{feature.name}</FeatureName>
                <FeatureSourceTag>{renderSourceIcon()}</FeatureSourceTag>
            </CardHeader>
            <CardBody>
                <FeatureDescription>{feature.description}</FeatureDescription>
                <FeatureDocs href={feature.fileURL} target="_blank">
                    {feature.fileURL}
                </FeatureDocs>
            </CardBody>
            <CardFooter>
                <FeatureStatusTag>{renderStatusIcon()}</FeatureStatusTag>
                <EditionIconButton
                    data-testid="edit-icon-button"
                    onClick={() => onEdit(feature)}
                    published={isFeaturePublished()}
                    disabled={isFeaturePublished()}
                >
                    <EditIcon sx={{ color: `${getIconColor()}` }} />
                </EditionIconButton>
                <DeleteIconButton
                    data-testid="delete-icon-button"
                    onClick={() => onDelete(feature)}
                    published={isFeaturePublished()}
                    disabled={isFeaturePublished()}
                >
                    <DeleteIcon sx={{ color: `${getIconColor()}` }} />
                </DeleteIconButton>
            </CardFooter>
        </CardContainer>
    )
}

interface ICardContainerProps {
    published?: boolean
}

const CardContainer = styled.div<ICardContainerProps>`
    display: flex;
    flex-direction: column;
    border: 1px solid ${COLORS.borderGray};
    background-color: ${({ published }) =>
        published ? COLORS.borderGray : COLORS.primaryWhite};
    font-family: Roboto;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 24px 34px 24px 10px;
`

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
`

const FeatureName = styled.span`
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primaryGray};
`
const FeatureSourceTag = styled.div`
    margin-left: 5px;
`

const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`

const FeatureDescription = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.09px;
    color: ${COLORS.primaryGray};
`
const FeatureDocs = styled.a`
    text-transform: none;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.13px;
    color: ${COLORS.urlBlue};
    margin-top: 16px;
`

const CardFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: center;
`

const FeatureStatusTag = styled.div``
const EditionIconButton = styled.button<ICardContainerProps>`
    cursor: ${({ published }) => (published ? 'default' : 'pointer')};
    border: none;
    background-color: transparent;
    margin-left: 12px;
`
const DeleteIconButton = styled(EditionIconButton)``

export default FeatureCardComponent
