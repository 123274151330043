import styled from 'styled-components'

import SearchIcon from '../assets/icons/SVG/SearchIcon'
import { COLORS } from '../commons/Colors'
import RoundButtonComponent from '../commons/components/RoundButtonComponent'

export type NotRegistredComponentProps = {
    title: string
    onClick: () => void
}

function NotRegistredComponent({
    title,
    onClick,
}: NotRegistredComponentProps): JSX.Element {
    const getButtonText = (): string =>
        title === 'feature' ? 'Nova Feature' : 'Nova Versão'

    return (
        <>
            <NewButtonContainer>
                <RoundButtonComponent
                    text={getButtonText()}
                    onClick={onClick}
                />
            </NewButtonContainer>
            <NotRegistredContainer>
                <IconContainer>
                    <SearchIcon />
                </IconContainer>
                <NotRegistredTextInformation>
                    Você ainda não possui nenhum registro de {title} no sistema
                    <strong> clique no botão nova {title} para começar!</strong>
                </NotRegistredTextInformation>
            </NotRegistredContainer>
        </>
    )
}

const NotRegistredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 200px;
`

const NewButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 50px 26px 0 0;
`

const IconContainer = styled.div`
    color: ${COLORS.primaryOrange};
    margin-bottom: 30px;
`

const NotRegistredTextInformation = styled.p`
    font-family: Roboto;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.09;
    text-align: center;
    color: ${COLORS.primaryOrange};
    text-transform: uppercase;
    width: 60%;
`

export default NotRegistredComponent
