export default function InfoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm-.6 9V5.4h1.2V9H5.4zm0-6v1.2h1.2V3H5.4z"
                fill="#6D7278"
            />
            <path
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm-.6 9V5.4h1.2V9H5.4zm0-6v1.2h1.2V3H5.4z"
                fill="#6D7278"
            />
        </svg>
    )
}
