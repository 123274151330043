import { getAPIKey, getBaseURL, getOrigin } from '../../environment'
import { Config } from '../../models/Config'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const endpoint = '/clients/configurations'
const origin = getOrigin()

export const getConfigByMillId = (
    millId: string
): Promise<{ data: Config }> => {
    const queryParams = new URLSearchParams({
        millId,
    })

    return fetch(`${baseURL}${endpoint}?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createConfig = (
    config: Partial<Config>
): Promise<{ data: Config }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(config),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const updateConfig = (
    mill: Partial<Config>,
    id: string
): Promise<{ data: Config }> => {
    return fetch(`${baseURL}${endpoint}/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(mill),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
