import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Button } from '@mui/material'
import styled from 'styled-components'

import GlobalThemeProvider from '../../style/GlobalTheme'
import { ColorTypes, SizeTypes, VaryantTypes } from '../utils/Types'
import LoadingComponent from './LoadingComponent'

export type RoundButtonComponentProps = {
    text: string
    disabled?: boolean
    onClick?: (item?: any) => void | null | Promise<any>
    color?: ColorTypes
    fileUpload?: boolean
    size?: SizeTypes
    isLoading?: boolean
    variant?: VaryantTypes
}

function RoundButtonComponent({
    text,
    disabled,
    onClick,
    color = 'primary',
    fileUpload = false,
    size = 'small',
    isLoading = false,
    variant = 'contained',
}: RoundButtonComponentProps): JSX.Element {
    if (fileUpload) {
        if (isLoading) {
            return (
                <GlobalThemeProvider>
                    <Button
                        disabled
                        component="label"
                        variant="contained"
                        startIcon={<LoadingComponent size="small" />}
                        href="#file-upload"
                        size={size}
                    >
                        {text}
                    </Button>
                </GlobalThemeProvider>
            )
        }

        return (
            <GlobalThemeProvider>
                <Button
                    disabled={disabled}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    href="#file-upload"
                    size={size}
                >
                    {text}
                    <VisuallyHiddenInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const file = e.target.files?.length
                                ? e.target.files[0]
                                : null

                            if (onClick) {
                                onClick(file)
                                e.target.value = null as unknown as string
                            }
                        }}
                        type="file"
                    />
                </Button>
            </GlobalThemeProvider>
        )
    }

    return (
        <GlobalThemeProvider>
            <Button
                onClick={onClick}
                color={color}
                variant={variant}
                disabled={disabled}
                size={size}
            >
                {text}
            </Button>
        </GlobalThemeProvider>
    )
}

RoundButtonComponent.defaultProps = {
    disabled: false,
    onClick: () => null,
}

const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`

export default RoundButtonComponent
