import React from 'react'
import styled from 'styled-components'

import FarmTellMillsLogo from '../../assets/logo/FarmTellMillsLogo.png'
import { useUserContext } from '../../context/UserContext'
import { COLORS } from '../Colors'
import SideMenu from './SideMenu'

const MainHeaderComponent: React.FC = () => {
    const { user } = useUserContext()
    return (
        <HeaderContainer>
            <LeftSideContent>
                <ProdapLogo src={FarmTellMillsLogo} />
            </LeftSideContent>
            <RightSideContent>
                <DefaultText>Olá, </DefaultText>
                <UserName>{user?.name}</UserName>
                <SideMenu />
            </RightSideContent>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.div`
    align-items: center;
    background-color: ${COLORS.primaryWhite};
    box-shadow: 0 1px 4px 0 #c7c7cc;
    display: flex;
    justify-content: space-between;
    height: 60px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
`

const LeftSideContent = styled.div`
    width: 50%;
    padding-left: 2vw;
`

const ProdapLogo = styled.img`
    width: 106px;
    height: auto;
`

const RightSideContent = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding-right: 2vw;
`

const DefaultText = styled.span`
    color: ${COLORS.mainHeaderText};
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.13px;
`

const UserName = styled(DefaultText)`
    font-weight: bold;
    padding-right: 1vw;
`

export default MainHeaderComponent
