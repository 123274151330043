import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import debounce from '../../commons/utils/Debounce'
import { SeverityTypes, SnackbarConfigTypes } from '../../commons/utils/Types'
import * as ConfigurationAPI from '../API/ConfigurationAPI'
import * as MillAPI from '../API/MillAPI'
import { ConfigForm, MillForm, TypeForm, avaliableUfs } from '../Utils'
import MillManagementFormView from './MillManagementFormView'

function MillManagementFormContainer(): JSX.Element {
    const { id } = useParams<string>()
    const navigate = useNavigate()
    const [millForm, setMillForm] = useState<MillForm>({
        legacyCode: '',
        name: '',
        corporateName: '',
        uf: '',
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [configId, setConfigId] = useState<string | undefined>(undefined)
    const [configForm, setConfigForm] = useState<ConfigForm>({
        allowsOrderEdition: false,
        stockSafetyDays: undefined,
        allowsOrderOutOfPricePolicy: false,
        chapaValue: undefined,
        usePallet: false,
        useChapa: false,
        allowsCustomDeadline: false,
    })
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })

    const isEdition = !!id

    useEffect(() => {
        async function fetchMill(): Promise<void> {
            try {
                const response = await MillAPI.getMillById(id!)
                const mill = response.data
                setMillForm({
                    legacyCode: mill.legacyCode || '',
                    name: mill.name,
                    corporateName: mill.corporateName || '',
                    uf: mill.uf || '',
                })
            } catch (err) {
                console.error(err)
                handleSnackBar('Erro ao buscar Fábrica', 'error')
            }
        }

        async function fetchConfig(): Promise<void> {
            try {
                const config = (await ConfigurationAPI.getConfigByMillId(id!))
                    .data
                setConfigForm({
                    allowsOrderEdition: config.allowsOrderEdition || false,
                    stockSafetyDays: config.stockSafetyDays || undefined,
                    allowsOrderOutOfPricePolicy:
                        config.allowsOrderOutOfPricePolicy || false,
                    chapaValue: config.chapaValue,
                    usePallet: config.usePallet || false,
                    useChapa: config.useChapa || false,
                    allowsCustomDeadline: config.allowsCustomDeadline || false,
                })
                setConfigId(config.id)
            } catch (err) {
                console.error(err)
                handleSnackBar(
                    'Erro ao buscar Configurações da Fábrica',
                    'error'
                )
            }
        }

        if (isEdition) {
            fetchMill()
            fetchConfig()
        }
    }, [isEdition])

    const handleSnackBar = (message: string, status: SeverityTypes): void => {
        setSnackBarConfig({
            message,
            isVisible: true,
            status,
        })
    }

    const handleFormChange = (
        form: TypeForm,
        field: string,
        value: string | number | boolean
    ): void => {
        if (form === TypeForm.Mill) {
            setMillForm({ ...millForm, [field]: value })
        } else if (form === TypeForm.Config) {
            setConfigForm({ ...configForm, [field]: value })
        }
    }

    const validateFields = (): boolean => {
        return millForm.name !== ''
    }

    const handleSave = async (): Promise<void> => {
        setLoading(true)
        let millId
        try {
            if (isEdition) {
                await MillAPI.updateMill(millForm, id!)
                millId = id
            } else {
                const millCadastrada = await MillAPI.createMill(millForm)
                millId = millCadastrada.data.id
            }
        } catch (err) {
            console.error(err)
            handleSnackBar(
                'Não foi possível salvar a fábrica, tente novamente mais tarde',
                'error'
            )
            return
        }

        try {
            if (configId) {
                await ConfigurationAPI.updateConfig(
                    configForm,
                    configId as string
                )
            } else {
                await ConfigurationAPI.createConfig({
                    ...configForm,
                    millId,
                })
            }
        } catch (err) {
            console.error(err)
            handleSnackBar(
                'Não foi possível salvar as configurações, tente novamente mais tarde',
                'error'
            )
            return
        }
        handleSnackBar('Fábrica salva com sucesso!', 'success')
        setLoading(false)
        debounce(() => {
            navigate('/gestao/fabrica')
        }, 1000)
    }

    const handleCloseSnackbar = (): void => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    return (
        <MillManagementFormView
            millForm={millForm}
            configForm={configForm}
            handleFormChange={handleFormChange}
            avaliableUfs={avaliableUfs}
            handleSave={handleSave}
            validateFields={validateFields}
            isEdition={isEdition}
            snackbarConfig={snackbarConfig}
            handleCloseSnackbar={handleCloseSnackbar}
            loading={loading}
        />
    )
}

export default MillManagementFormContainer
