import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import TabSelectorComponent from '../../commons/components/TabSelectorComponent'
import { AvailableItem } from '../../models/AvailableItem'
import { ParameterOptions } from '../../models/ParameterOption'
import PaymentTermsTab from './paymentTermsTab/PaymentTermsTab'
import SalesChannelTab from './salesChannelTab/SalesChannelTab'
import SegmentPlantsTab from './segmentPlantsTab/SegmentPlantsTab'
import StatesConfigTab from './statesConfigTab/StatesConfigTab'

type CustomerProfileViewProps = {
    availableItems: AvailableItem[]
    availablePaymentTerms: AvailableItem[]
    parameterOptions: ParameterOptions
    isSnackbarOpen: boolean
    handleCloseSnackbar: React.Dispatch<React.SetStateAction<boolean>>
}

export type UnmountedTabTypes = {
    label: string
    renderTabComponent: () => JSX.Element
}

export default function CustomerProfileView({
    availableItems,
    availablePaymentTerms,
    parameterOptions,
    isSnackbarOpen,
    handleCloseSnackbar,
}: CustomerProfileViewProps) {
    const { states, salesChannels, plants } = parameterOptions

    const [selectedTab, setSelectedTab] = useState<null | UnmountedTabTypes>(
        null
    )
    const [pageTabs, setpageTabs] = useState<UnmountedTabTypes[]>([])

    useEffect(() => {
        const salesChannelTab = {
            label: 'Setor Industrial',
            renderTabComponent: () => (
                <SalesChannelTab
                    parameters={salesChannels}
                    availableItems={availableItems}
                />
            ),
        }

        const customerStateTab = {
            label: 'Estado do Cliente',
            renderTabComponent: () => (
                <StatesConfigTab
                    parameters={states}
                    availableItems={availableItems}
                />
            ),
        }

        const segmentPlantsTab = {
            label: 'Plantas de Segmento',
            renderTabComponent: () => (
                <SegmentPlantsTab
                    parameters={plants}
                    availableItems={availableItems}
                />
            ),
        }

        const paymentTermsTab = {
            label: 'Condições de pagamento',
            renderTabComponent: () => (
                <PaymentTermsTab
                    parameters={salesChannels}
                    availablePaymentTerms={availablePaymentTerms}
                />
            ),
        }

        setpageTabs([
            salesChannelTab,
            customerStateTab,
            segmentPlantsTab,
            paymentTermsTab,
        ])

        setSelectedTab(salesChannelTab)
    }, [parameterOptions, availableItems, availablePaymentTerms])

    return (
        <PageContainer>
            <PageWrapper>
                <PageTitle>
                    EXIBIÇÃO DAS INFORMAÇÕES NO APLICATIVO FARMTELL MILLS
                </PageTitle>
            </PageWrapper>
            <TabSelectorComponent
                tabNames={pageTabs}
                getSelectedTab={setSelectedTab}
                hasUpdatingBehavior
            />
            {selectedTab && selectedTab.renderTabComponent()}
            <SnackBarComponent
                severity="error"
                isVisible={isSnackbarOpen}
                message="Ocorreu um erro, por favor tente novamente mais tarde!"
                handleClose={() => handleCloseSnackbar((prev) => !prev)}
            />
        </PageContainer>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.primaryWhite};
    border: solid 1px ${COLORS.borderGray};
    margin: 15px 15px 0px 15px;
`
const PageWrapper = styled.section`
    align-self: center;
    width: 97%;
`

const PageTitle = styled.h1`
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    align: left;
    text-align: left;
    margin-top: 25px;
    color: ${COLORS.primaryGray};
`
