import { getBaseURL, getAPIKey, getOrigin } from '../../environment'
import { Mill } from '../../models/Mill'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const endpoint = '/clients/mills'
const origin = getOrigin()

export const getAllMills = (): Promise<{ data: Mill[] }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
