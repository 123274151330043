import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader'
import dayjs, { Dayjs } from 'dayjs'

import { COLORS } from '../Colors'

require('dayjs/locale/pt-br')

function CustomCalendarHeader(props: PickersCalendarHeaderProps<Dayjs>) {
    const { currentMonth, onMonthChange } = props

    const selectNextMonth = () =>
        onMonthChange(currentMonth.add(1, 'month'), 'left')

    const selectPreviousMonth = () =>
        onMonthChange(currentMonth.subtract(1, 'month'), 'right')

    return (
        <CustomCalendarHeaderRoot>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectPreviousMonth} color="primary">
                    <ChevronLeft />
                </IconButton>
            </Stack>
            <Typography variant="body2" fontWeight={600}>
                {currentMonth
                    .locale('pt-br')
                    .format('MMMM')
                    .toLocaleUpperCase()}
            </Typography>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectNextMonth} color="primary">
                    <ChevronRight />
                </IconButton>
            </Stack>
        </CustomCalendarHeaderRoot>
    )
}

type CustomDatePickerProps = {
    minDate?: dayjs.Dayjs
    maxDate?: dayjs.Dayjs
    defaultValue?: dayjs.Dayjs
    label: string
    onChange: (day: number) => void
}

export default function CustomDatePicker({
    minDate,
    maxDate,
    defaultValue,
    label,
    onChange,
}: CustomDatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                onChange={(dayJs) => {
                    onChange(dayJs?.date() as number)
                }}
                minDate={minDate}
                maxDate={maxDate}
                slotProps={{
                    day: {
                        sx: {
                            color: COLORS.brownishGrey,
                            fontWeight: 700,
                        },
                    },
                    layout: {
                        sx: {
                            '& .MuiDayCalendar-weekDayLabel': {
                                color: COLORS.primaryOrange,
                            },
                            backgroundColor: COLORS.borderGray,
                        },
                    },
                }}
                slots={{ calendarHeader: CustomCalendarHeader }}
                format="DD/MM/YYYY"
                label={label}
                defaultValue={defaultValue}
            />
        </LocalizationProvider>
    )
}

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
})
