import { useEffect, useState } from 'react'

import debounce from '../../commons/utils/Debounce'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { Mill } from '../../models/Mill'
import * as MillAPI from '../API/MillAPI'
import { MillListHeaders } from '../Utils'
import MillManagementListView from './MillManagementListView'

function MillManagementListContainer(): JSX.Element {
    const [mills, setMills] = useState<Mill[]>([])
    const [millsQuantity, setMillsQuantity] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [page, setPage] = useState<number>(1)
    const [filter, setFilter] = useState<string>('')
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const headers: MillListHeaders[] = [
        { label: 'Código', value: 'legacyCode' },
        { label: 'Nome Fantasia', value: 'name' },
        { label: 'Razão Social', value: 'corporateName' },
        { label: '', value: 'icons' },
    ]
    const size = 10

    async function fetchMills(): Promise<void> {
        try {
            const response = await MillAPI.getAllMills({
                page,
                size,
                filter,
            })

            if (response.data) {
                setMills(response.data)
                setMillsQuantity(response.total)
                setIsLoading(false)
            }
        } catch (err) {
            console.error(err)
            setIsLoading(false)
            setSnackBarConfig({
                message: 'Erro ao buscar Fábricas',
                isVisible: true,
                status: 'error',
            })
        }
    }

    useEffect(() => {
        fetchMills()
    }, [page, filter])

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value)
    }

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        debounce(() => {
            setFilter(event.target.value)
            setPage(1)
        })
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    return (
        <MillManagementListView
            page={page}
            size={size}
            totalRecords={millsQuantity}
            isLoading={isLoading}
            handleFilter={handleFilter}
            handlePagination={handlePagination}
            headers={headers}
            rows={mills}
            snackbarConfig={snackbarConfig}
            handleCloseSnackbar={handleCloseSnackbar}
        />
    )
}

export default MillManagementListContainer
