import React from 'react'
import { MdAccountCircle } from 'react-icons/md'
import styled from 'styled-components'

import MillIcon from '../../assets/icons/SVG/MillIcon'
import { useUserContext } from '../../context/UserContext'
import { COLORS } from '../Colors'

export const UserProfileComponent = () => {
    const { user, mill } = useUserContext()
    return (
        <Container>
            <MdAccountCircle size={60} />
            <UserLogin>{user?.login}</UserLogin>
            <UserMillContainer>
                <MillIcon />
                <UserMill>{mill?.name}</UserMill>
            </UserMillContainer>
        </Container>
    )
}

const Container = styled.div`
    color: ${COLORS.primaryGray};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    border-bottom: 1px solid ${COLORS.lightBlueGreyTwo};
`
const UserLogin = styled.span``
const UserMillContainer = styled.span`
    margin-top: 5px;
`
const UserMill = styled.span`
    margin-left: 5px;
`

export default UserProfileComponent
