import { useEffect, useState } from 'react'

import { CustomerProfileTabProps } from '../../../models/CustomerProfileTab'
import {
    PermissionConfig,
    Configuration,
} from '../../../models/PermissionConfig'
import { getConfigList, saveConfigList } from '../../AppPermissionsAPI'
import CustomConfigClientProfile from '../components/CustomConfigClientProfile'
import {
    formatConfigItems,
    formatNewConfigList,
} from '../utils/ConfigListFormaters'

export default function StatesConfigTab({
    parameters,
    availableItems,
}: CustomerProfileTabProps) {
    const [configList, setConfigList] = useState<Configuration[]>([])
    const [configItems, setConfigItems] = useState<PermissionConfig[]>([])
    const [initialConfigItems, setInitialConfigItems] = useState<
        PermissionConfig[]
    >([])
    const [selectedParameterValue, setSelectedParameterValue] =
        useState<string>('all')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [configLoadingFailed, setConfigLoadingFailed] =
        useState<boolean>(false)

    useEffect(() => {
        if (configList.length > 0 && selectedParameterValue) {
            const newConfigItems = formatConfigItems(
                selectedParameterValue,
                configList
            )
            setInitialConfigItems(newConfigItems)
            setConfigItems(newConfigItems)
        }
    }, [selectedParameterValue, configList])

    useEffect(() => {
        loadConfigList()
    }, [])

    const loadConfigList = async () => {
        try {
            setIsLoading(true)
            const {
                data: { config },
            } = await getConfigList('products/statesConfig')
            setConfigList(config)
        } catch (err) {
            console.error(err)
            setConfigLoadingFailed(true)
            setConfigList([])
        } finally {
            setIsLoading(false)
        }
    }

    const handleSave = async () => {
        if (!selectedParameterValue) return
        const body = formatNewConfigList(
            selectedParameterValue,
            configList,
            configItems
        )

        try {
            await saveConfigList('products/statesConfig', body)
        } catch (error) {
            console.error(`Erro ao salvar configuração por estado. ${error}`)
            throw error
        } finally {
            loadConfigList()
        }
    }

    const handleCancel = (configs: PermissionConfig[]) => {
        setConfigItems(configs)
    }

    return (
        <CustomConfigClientProfile
            availableItems={availableItems}
            configItems={configItems}
            initialConfigItems={initialConfigItems}
            parameters={parameters}
            parameterType="statesConfig"
            isLoading={isLoading}
            configLoadingFailed={configLoadingFailed}
            onCancel={handleCancel}
            onChangeConfigItems={setConfigItems}
            onChangeSelectedParameterValue={setSelectedParameterValue}
            onSave={handleSave}
        />
    )
}
