import { getBaseURL, getAPIKey, getOrigin } from '../../environment'
import { ImportError, ImportOption } from '../utils'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const getEndpoint = (functionName: string, millId: string, method: string) => {
    if (functionName === 'imports') {
        return `/imports/${millId}/${method}`
    }
    return `/${functionName}/imports/${millId}/${method}`
}

const origin = getOrigin()

export const validateImportFile = async (params: {
    millId: string
    importType: ImportOption
    file: any
}): Promise<{ data: ImportError[] }> => {
    const { importType, millId, file } = params
    const formData = new FormData()
    formData.append('file', file)
    formData.append('field', importType.value)

    return fetch(
        `${baseURL}${getEndpoint(
            importType.function,
            millId,
            importType.methods.validate
        )}`,
        {
            method: 'POST',
            headers: {
                'x-api-key': APIKey,
                'x-origin': origin,
            },
            body: formData,
        }
    )
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const sendImportFile = async (params: {
    millId: string
    importType: ImportOption
    file: any
}): Promise<void> => {
    const { importType, millId, file } = params
    const formData = new FormData()
    formData.append('file', file)
    formData.append('field', importType.value)

    return fetch(
        `${baseURL}${getEndpoint(
            importType.function,
            millId,
            importType.methods.import
        )}`,
        {
            method: 'POST',
            headers: {
                'x-api-key': APIKey,
                'x-origin': origin,
            },
            body: formData,
        }
    )
        .then((response: Response) => {
            if (response.status !== 200)
                throw new Error('Erro na importação do arquivo.')
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
