import EditIcon from '@mui/icons-material/Edit'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import FilterComponent from '../../commons/components/FilterComponent'
import LoadingComponent from '../../commons/components/LoadingComponent'
import MainHeaderComponent from '../../commons/components/MainHeaderComponent'
import RoundButtonComponent from '../../commons/components/RoundButtonComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import TableListWithPaginationComponent from '../../commons/components/TableListWithPaginationComponent'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { Mill } from '../../models/Mill'
import GlobalThemeProvider from '../../style/GlobalTheme'
import { MillListHeaders } from '../Utils'

type MillManagementListViewProps = {
    page: number
    size: number
    handlePagination: (_event: React.ChangeEvent<unknown>, page: number) => void
    handleFilter: (_event: React.ChangeEvent<HTMLInputElement>) => void
    totalRecords: number
    headers: MillListHeaders[]
    rows: Mill[]
    isLoading: boolean
    snackbarConfig: SnackbarConfigTypes
    handleCloseSnackbar: () => void
}

function MillManagementListView({
    page,
    size,
    handlePagination,
    handleFilter,
    totalRecords,
    rows,
    headers,
    isLoading,
    snackbarConfig,
    handleCloseSnackbar,
}: MillManagementListViewProps): JSX.Element {
    function createRow(mills: Mill[]) {
        return mills.map((mill) => {
            const { legacyCode, name, corporateName, id } = mill
            return {
                legacyCode,
                name,
                corporateName,
                icons: (
                    <ButtonsContainer key={mill.id}>
                        <Link
                            to={`/gestao/fabrica/${id}`}
                            data-testid={`edit-mill-${id}`}
                        >
                            <EditIcon sx={{ color: COLORS.primaryGray }} />
                        </Link>
                    </ButtonsContainer>
                ),
            }
        })
    }
    return (
        <GlobalThemeProvider>
            <PageContainer>
                <MainHeaderComponent />
                <PageTitle>Gestão de Fábricas</PageTitle>
                {isLoading ? (
                    <LoadingComponent />
                ) : (
                    <ListContainer>
                        <FilterContainer>
                            <FilterComponent
                                placeHolder="Pesquise por Código, Fábrica, Nome Corporativo"
                                onChange={handleFilter}
                            />
                            <Link to="/gestao/fabrica/novo">
                                <RoundButtonComponent text="Nova Fábrica" />
                            </Link>
                        </FilterContainer>
                        <TableListWithPaginationComponent
                            totalRecords={totalRecords}
                            onChange={handlePagination}
                            currentPage={page}
                            rows={createRow(rows)}
                            headers={headers}
                            maxRowsInList={size}
                        />
                    </ListContainer>
                )}
            </PageContainer>

            <SnackBarComponent
                handleClose={handleCloseSnackbar}
                isVisible={snackbarConfig.isVisible}
                message={snackbarConfig.message}
                severity={snackbarConfig.status}
            />
        </GlobalThemeProvider>
    )
}

export default MillManagementListView

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 90%;
`
const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    margin-bottom: 20px;
`

const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    font-weight: normal;
    padding: 2vw;
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
