import styled from 'styled-components'

import { COLORS } from '../Colors'

export type RoundTagComponentProps = {
    text: string
    color: string
    small?: boolean
}
function RoundTagComponent({
    text,
    color,
    small,
}: RoundTagComponentProps): JSX.Element {
    if (small)
        return (
            <SmallTag color={color}>
                <SmallText>{text}</SmallText>
            </SmallTag>
        )

    return (
        <RoundTag color={color}>
            <Text>{text}</Text>
        </RoundTag>
    )
}

RoundTagComponent.defaultProps = {
    small: false,
}

const SmallTag = styled.button`
    background-color: ${({ color }) => color || COLORS.primaryOrange};
    min-width: 36px;
    min-height: 13px;
    border-radius: 24px;
    border-width: none;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const RoundTag = styled(SmallTag)`
    min-width: 68px;
    min-height: 19px;
`

const SmallText = styled.span`
    margin: 1px 0 0 0;
    font-family: 'Roboto';
    font-size: 9px;
    color: ${COLORS.primaryWhite};
    text-align: center;
`

const Text = styled(SmallText)`
    font-size: 10px;
`

export default RoundTagComponent
