import { getBaseURL, getAPIKey, getOrigin } from '../../environment'
import { Mill } from '../../models/Mill'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const endpoint = '/clients/mills'
const origin = getOrigin()

export const getAllMills = ({
    page,
    size,
    filter,
}: GetAllMillsParams): Promise<{ data: Mill[]; total: number }> => {
    const queryParams = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
    })

    if (filter) queryParams.append('filter', filter)

    return fetch(`${baseURL}${endpoint}/?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createMill = (mill: Partial<Mill>): Promise<{ data: Mill }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(mill),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const updateMill = (
    mill: Partial<Mill>,
    id: string
): Promise<{ data: Mill }> => {
    return fetch(`${baseURL}${endpoint}/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(mill),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getMillById = (id: string): Promise<{ data: Mill }> => {
    return fetch(`${baseURL}${endpoint}/${id}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

type GetAllMillsParams = {
    page: number
    size: number
    filter?: string
}
