import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { COLORS } from '../commons/Colors'
import Checkbox from '../commons/components/CheckboxComponent'
import LoadingComponent from '../commons/components/LoadingComponent'
import { IFeature } from '../models/IFeature'
import { IVersion } from '../models/IVersion'
import { getAllFeatures } from './API/FeatureAPI'
import * as VersionUtils from './utils/VersionUtils'

type FeatureSelectorComponentProps = {
    onChange: (value: Array<IFeature>, name: string) => void
    getValue: (key: string) => string | Array<IFeature>
    selectedSource: string
    selectedVersion: IVersion
}

export default function FeatureSelectorComponent({
    onChange,
    getValue,
    selectedSource,
    selectedVersion,
}: FeatureSelectorComponentProps): JSX.Element {
    const [features, setFeatures] = useState<Array<IFeature>>([])
    const [value, setValue] = useState<Array<IFeature>>(getSelectorValue())
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const fetchFeatures = async () => {
        setIsLoading(true)
        const fetchedFeatures = await getAllFeatures()
        const availableFeatures = VersionUtils.handleFeatures(
            fetchedFeatures.data,
            selectedSource,
            selectedVersion
        )

        setFeatures(availableFeatures)
        setIsLoading(false)
    }

    const isFeatureSelected = (featureId: string): boolean => {
        return value.some((feature) => featureId === feature.id)
    }

    useEffect(() => {
        fetchFeatures()
        setValue(getSelectorValue())
    }, [selectedSource])

    function getSelectorValue(): Array<IFeature> {
        const selectedFeatures = getValue('features')

        return Array.isArray(selectedFeatures) ? selectedFeatures : []
    }

    const onSelectFeature = (event: any) => {
        if (isFeatureSelected(event.target.value)) {
            removeFeature(event.target.value)
        } else {
            addFeature(event.target.value)
        }
    }

    const addFeature = (featureId: string) => {
        setValue((current: Array<IFeature>) => {
            const selectedFeatures = Array.from(current)
            const featureToAdd = features.find((feature) =>
                feature.id ? featureId === feature.id : false
            )

            if (featureToAdd) selectedFeatures.push(featureToAdd)

            onChange(selectedFeatures, 'features')
            return selectedFeatures
        })
    }

    const removeFeature = (featureId: string) => {
        setValue((current: Array<IFeature>) => {
            const selectedFeatures = current.filter(
                (feature) => featureId !== feature.id
            )

            onChange(selectedFeatures, 'features')
            return selectedFeatures
        })
    }

    const renderFeature = (feature: IFeature) => {
        if (!feature.id) return null

        return (
            <FeatureContainer
                key={feature.id}
                color={
                    isFeatureSelected(feature.id)
                        ? COLORS.lightishOrange
                        : COLORS.primaryWhite
                }
                data-testid={
                    isFeatureSelected(feature.id)
                        ? 'selected-feature'
                        : 'unselected-feature'
                }
            >
                <Checkbox
                    checked={isFeatureSelected(feature.id)}
                    onChange={(e) => onSelectFeature(e)}
                    value={feature.id}
                />
                <FeatureLabel>{feature.name}</FeatureLabel>
            </FeatureContainer>
        )
    }

    if (isLoading)
        return (
            <Container>
                <LoadingContainer>
                    <LoadingComponent />
                </LoadingContainer>
            </Container>
        )

    return (
        <>
            <Label htmlFor="feature-selector">*FUNCIONALIDADES</Label>
            <Container
                aria-labelledby="feature-selector"
                data-testid="feature-selector"
            >
                {features.map((feature) => renderFeature(feature))}
            </Container>
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    width: 100%;
    height: 220px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);

    &::-webkit-scrollbar-track {
        margin-top: 13px;
        margin-bottom: 13px;
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        opacity: 0.5;
        background-color: ${COLORS.lightOrange};
    }
`

const FeatureContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 9px 0px 11px 19px;
    background-color: ${(props) => props.color};
`

const FeatureLabel = styled.label`
    height: 24px;
    margin: 0 0 0 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.1px;
    text-align: left;
    color: #666;
`

const LoadingContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    height: 100%;
`

const Label = styled.label`
    text-transform: uppercase;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primaryGray};
    line-height: 2;
    margin-top: 15px;
    margin-bottom: 5px;
`
