import styled from 'styled-components'

import { COLORS } from '../Colors'

export type TextInputComponentProps = {
    labelText: string
    name: string
    onChange: (value: string, name: string) => void | null
    getValue: (name: string) => any
    isTextArea?: boolean
    placeholder?: string
}

function TextInputComponent({
    labelText,
    name,
    onChange,
    getValue,
    isTextArea,
    placeholder = '',
}: TextInputComponentProps): JSX.Element {
    if (isTextArea)
        return (
            <InputContainer>
                <InputLabel htmlFor={`id-${labelText}`}>{labelText}</InputLabel>
                <TextArea
                    name={name}
                    id={`id-${labelText}`}
                    onChange={({ target }) =>
                        onChange(target.value, target.name)
                    }
                    value={getValue(name) || ''}
                />
            </InputContainer>
        )

    return (
        <InputContainer>
            <InputLabel htmlFor={`id-${labelText}`}>{labelText}</InputLabel>
            <TextInput
                id={`id-${labelText}`}
                onChange={({ target }) => onChange(target.value, name)}
                value={getValue(name) || ''}
                placeholder={placeholder}
            />
        </InputContainer>
    )
}

TextInputComponent.defaultProps = {
    isTextArea: false,
}

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const InputLabel = styled.label`
    text-transform: uppercase;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primaryGray};
    line-height: 2;
`

const TextInput = styled.input`
    font-family: Roboto;
    height: 20px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    line-height: 1.43;
    :focus {
        outline: none;
    }
`

const TextArea = styled.textarea`
    font-family: Roboto;
    height: 100px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    line-height: 1.43;
    :focus {
        outline: none;
    }
    resize: none;
`

export default TextInputComponent
