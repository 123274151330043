export const formatCurrency = (value: number): string => {
    const floatValue = parseFloat(`${value}`)
    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(floatValue)

    return formattedValue
        .replace(/^R\$\s/g, '')
        .replace(/NaN/g, '')
        .replace(/^0,00$/g, '')
}

export const removeCurrencyFormat = (value: string): number => {
    const formattedValue = value
        .replace(/\d,0$/g, '')
        .replace(/,00/g, '')
        .replace(/\./g, '')
    const parsedNumber = parseInt(formattedValue, 10)
    return Number.isNaN(parsedNumber) ? 0 : parsedNumber
}
