function FactoryIcon(): JSX.Element {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#9bj43ylo1a)">
                <path
                    d="M0 5.154h18.804v13.952a.902.902 0 0 1-.912.894H.912A.902.902 0 0 1 0 19.106V5.154z"
                    fill="#666"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 9.498h2.542V7H2v2.498zm4.542 8.995h2.542v-2.497H6.542v2.497zm-2 0H2v-2.497h2.542v2.497zM2 13.996h2.542v-2.498H2v2.498zm7.084 0H6.542v-2.498h2.542v2.498zm2-6.996v2.498h2.542V7h-2.542zm-2 2.498H6.542V7h2.542v2.498zm2 4.498h2.542v-2.498h-2.542v2.498zm2.542 4.497h-2.542v-2.497h2.542v2.497z"
                    fill="#fff"
                />
                <path
                    d="M1.98 3.476c1.092 0 1.977.867 1.977 1.938v.721a.28.28 0 0 1-.283.277H.283A.278.278 0 0 1 0 6.135v-.718c0-1.07.888-1.941 1.98-1.941zM6.17 3.476c1.093 0 1.978.867 1.978 1.938v.721a.28.28 0 0 1-.283.277H4.477a.28.28 0 0 1-.283-.277v-.718c0-1.07.888-1.941 1.98-1.941H6.17zM17.728 0C19.534 0 21 1.437 21 3.208v15.73c0 .588-.487 1.062-1.083 1.062h-5.325a.168.168 0 0 1-.17-.167V3.24c0-1.788 1.48-3.24 3.306-3.24zM10.422 3.476c1.092 0 1.977.867 1.977 1.938v.721a.28.28 0 0 1-.283.277H8.728a.28.28 0 0 1-.282-.277v-.718c0-1.07.888-1.941 1.98-1.941h-.004z"
                    fill="#666"
                />
            </g>
            <defs>
                <clipPath id="9bj43ylo1a">
                    <path fill="#fff" d="M0 0h21v20H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FactoryIcon
