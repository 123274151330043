import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import LoadingComponent from '../../commons/components/LoadingComponent'
import RoundButtonComponent from '../../commons/components/RoundButtonComponent'
import SearchInputComponent from '../../commons/components/SearchInputComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { IVersion } from '../../models/IVersion'
import * as VersionAPI from '../API/VersionAPI'
import NotRegistredComponent from '../NotRegistredComponent'
import VersionCardListComponent from '../VersionCardListComponent'
import VersionCreationModalComponent from '../VersionCreationModalComponent'

export default function VersionTabContentScreen(): JSX.Element {
    const [versions, setVersions] = useState<Array<IVersion>>([])
    const [filteredVersions, setFilteredVersions] = useState<IVersion[]>(
        versions || []
    )
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isNewVersionModalVisible, setIsNewVersionModalVisible] =
        useState<boolean>(false)
    const [versionToEdit, setVersionToEdit] = useState<IVersion | undefined>()
    const [selectedVersions, setSelectedVersions] = useState<IVersion[]>([])
    const [snackBarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })

    useEffect(() => {
        fetchVersions()
    }, [])

    const fetchVersions = async () => {
        setIsLoading(true)
        const fetchedVersions = await VersionAPI.getAllVersions()
        setVersions(fetchedVersions.data)
        setIsLoading(false)
    }

    const handleOnEditVersion = (version: IVersion) => {
        setVersionToEdit(version)
        setIsNewVersionModalVisible(true)
    }

    const handleOnCloseModal = () => {
        setVersionToEdit(undefined)
        setIsNewVersionModalVisible(false)
    }

    const handleOnCloseSnackBar = () => {
        setSnackBarConfig({ message: '', isVisible: false, status: 'success' })
    }

    const handleOnSaveVersion = async (version: IVersion): Promise<void> => {
        setIsLoading(true)

        const result = versionToEdit?.id
            ? await VersionAPI.updateVersion(version)
            : await VersionAPI.createVersion(version)

        if (result.data) {
            setSnackBarConfig({
                message: `Versão ${
                    versionToEdit?.id ? 'editada' : 'criada'
                } com sucesso!`,
                isVisible: true,
                status: 'success',
            })

            try {
                await fetchVersions()
            } catch (e) {
                console.error(e)
            }

            return
        }

        setSnackBarConfig({
            message: 'Erro ao criar versão!',
            isVisible: true,
            status: 'error',
        })
    }

    const handleOnDeleteVersion = async (
        versionId: string | undefined
    ): Promise<void> => {
        setIsLoading(true)

        if (!versionId) {
            setSnackBarConfig({
                message: 'Erro ao deletar versão',
                isVisible: true,
                status: 'error',
            })

            return
        }

        const result = await VersionAPI.deleteVersion(versionId)
        if (result.message === 'Versão deletada com sucesso.') {
            setSnackBarConfig({
                message: result.message,
                isVisible: true,
                status: 'success',
            })

            try {
                fetchVersions()
            } catch (err) {
                console.error(err)
            }
        }
    }

    const handleOnPublishVersions = async (
        versions: Array<IVersion>
    ): Promise<void> => {
        let unexpectedError = false
        setIsLoading(true)

        await Promise.all(VersionAPI.publishVersions(versions)).then(
            (responses) => {
                responses.forEach((response) => {
                    if (response.message) {
                        setSnackBarConfig({
                            message:
                                'Uma ou mais versões falharam na publicação',
                            isVisible: true,
                            status: 'error',
                        })

                        unexpectedError = true
                    }
                })
            }
        )

        if (!unexpectedError)
            setSnackBarConfig({
                message: 'Versões publicadas com sucesso!',
                isVisible: true,
                status: 'success',
            })

        setSelectedVersions([])
        fetchVersions()
    }

    const handleCancelPublish = async (version: IVersion): Promise<void> => {
        setIsLoading(true)

        const response = await VersionAPI.cancelPublish(version)

        if (response.message) {
            setSnackBarConfig({
                message: 'Erro ao cancelar publicação',
                isVisible: true,
                status: 'error',
            })
        }

        setSnackBarConfig({
            message: 'Publicação da versão cancelada com sucesso!',
            isVisible: true,
            status: 'success',
        })

        setSelectedVersions([])
        fetchVersions()
    }

    const isVersionSelected = (versionId: string | undefined): boolean => {
        return selectedVersions.some((version) => versionId === version.id)
    }

    const onSelectVersion = (event: any) => {
        if (isVersionSelected(event.target.value)) {
            removeVersion(event.target.value)
        } else {
            addVersion(event.target.value)
        }
    }

    const addVersion = (versionId: string) => {
        setSelectedVersions((current: Array<IVersion>) => {
            const newSelectedVersions = Array.from(current)
            const versionToAdd = versions.find((version) =>
                version.id ? versionId === version.id : false
            )

            if (versionToAdd) newSelectedVersions.push(versionToAdd)
            return newSelectedVersions
        })
    }

    const removeVersion = (versionId: string) => {
        setSelectedVersions((current: Array<IVersion>) => {
            const newSelectedVersions = current.filter(
                (version) => versionId !== version.id
            )

            return newSelectedVersions
        })
    }

    if (isLoading)
        return (
            <Container>
                <LoadingContainer>
                    <LoadingComponent />
                </LoadingContainer>
            </Container>
        )

    return (
        <>
            {!versions.length ? (
                <Container>
                    <NotRegistredComponent
                        title="versão"
                        onClick={() => setIsNewVersionModalVisible(true)}
                    />
                </Container>
            ) : (
                <Container>
                    <TabHeaderContainer>
                        <FilterContainer>
                            <SearchInputComponent
                                label="Filtro"
                                placeholderText="Filtrar versão"
                                data={versions}
                                filterKeys={['name']}
                                getSearchedData={setFilteredVersions}
                            />
                        </FilterContainer>
                        <ButtonsContainer>
                            <RoundButtonComponent
                                text="Publicar"
                                onClick={() =>
                                    handleOnPublishVersions(selectedVersions)
                                }
                                disabled={selectedVersions.length === 0}
                                color="secondary"
                            />
                            <RoundButtonComponent
                                text="Nova Versão"
                                onClick={() =>
                                    setIsNewVersionModalVisible(true)
                                }
                            />
                        </ButtonsContainer>
                    </TabHeaderContainer>
                    <VersionCardListComponent
                        versions={filteredVersions}
                        onEdit={handleOnEditVersion}
                        onDelete={handleOnDeleteVersion}
                        onCancelPublish={handleCancelPublish}
                        isVersionSelected={isVersionSelected}
                        onSelectVersion={onSelectVersion}
                    />
                </Container>
            )}
            <VersionCreationModalComponent
                isVisible={isNewVersionModalVisible}
                setIsVisible={handleOnCloseModal}
                onSave={handleOnSaveVersion}
                selectedVersion={versionToEdit}
            />

            <SnackBarComponent
                severity={snackBarConfig.status}
                isVisible={snackBarConfig.isVisible}
                handleClose={handleOnCloseSnackBar}
                message={snackBarConfig.message}
            />
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    height: calc(100% - 260px);
    width: calc(100% - 64px);
    bottom: 28px;
    left: 32px;
    background-color: ${COLORS.primaryWhite};
    border: 1px solid ${COLORS.borderGray};
    overflow: auto;
`

const LoadingContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    height: 100%;
`

const FilterContainer = styled.div`
    display: flex;
    width: 30%;
    padding: 25px 0 8px 22px;
`

const TabHeaderContainer = styled.div`
    display: flex;
    width: 99%;
    justify-content: space-between;
`

const ButtonsContainer = styled.div`
    display: flex;
    margin: 46px 26px 46px 0;
    gap: 8px;
`
