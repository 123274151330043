import { useState } from 'react'
import styled from 'styled-components'

import InfoIcon from '../../../assets/icons/SVG/InfoIcon'
import { COLORS } from '../../../commons/Colors'
import BasicDropdownComponent from '../../../commons/components/BasicDropdownComponent'
import RadioButtonComponent from '../../../commons/components/RadioButtonComponent'
import { DropdownOptionTypes } from '../../../commons/utils/Types'
import { ParameterOption } from '../../../models/ParameterOption'

type ParameterOptionsProps = {
    parameters: ParameterOption[]
    parameterType:
        | 'paymentTerms'
        | 'segmentPlants'
        | 'statesConfig'
        | 'salesChannel'
    selectedParameterType: string
    onSelect: (title: string, type: string, value: string) => void
}

const TEXTS = {
    paymentTerms: {
        title: 'parâmetros para condições de pagamento',
        subtitle: '*selecione o setor industrial',
        placeholder: 'Selecionar setor',
        informationTextParameter: 'o setor industrial',
        informationTextOption:
            'as codinções de pagamento que serão relacionadas ao mesmo',
        defaultParameterTitle: 'PADRÃO PARA TODOS OS ',
    },
    salesChannel: {
        title: 'parâmetros para setor industrial',
        subtitle: '*selecione o setor industrial',
        placeholder: 'Selecionar setor',
        informationTextParameter: 'o setor industrial',
        informationTextOption: 'os produtos que serão relacionados ao mesmo',
        defaultParameterTitle: 'PADRÃO PARA TODOS OS SETORES',
    },
    segmentPlants: {
        title: 'parâmetros para planta de segmento',
        subtitle: '*selecione a planta',
        placeholder: 'Selecionar planta',
        informationTextParameter: 'a planta',
        informationTextOption: 'os produtos que serão relacionados à mesmo',
        defaultParameterTitle: 'PADRÃO PARA TODAS AS PLANTAS',
    },
    statesConfig: {
        title: 'parâmetros de associação por estado',
        subtitle: 'uf',
        placeholder: 'Selecionar estado',
        informationTextParameter: 'os produtos que serão relacionados ao mesmo',
        informationTextOption: 'o estado',
        defaultParameterTitle: 'PADRÃO PARA TODOS OS ESTADOS',
    },
}

export default function ParameterOptions({
    parameters,
    parameterType,
    selectedParameterType,
    onSelect,
}: ParameterOptionsProps) {
    const [hover, setHover] = useState<boolean>(false)
    const [specificOption, setSpecificOption] = useState<DropdownOptionTypes>({
        value: '',
        label: '',
    })
    const [specificSelected, setSpecificSelected] = useState<string>('')
    const [specificSelectedTitle, setSpecificSelectedTitle] =
        useState<string>('')

    const dropdownOptions = parameters.map((option) => ({
        label: option.option,
        value: option.code,
    }))

    const hashMapTitle = parameters.reduce(
        (acc, option) => ({ ...acc, [option.code]: option.title }),
        {}
    ) as any

    const getInfoText = () => {
        return `Para proceder as alterações é preciso selecionar ${TEXTS[parameterType].informationTextParameter} customizando ${TEXTS[parameterType].informationTextOption}, de forma distinta. Lembre-se de salvar suas alterações após editar cada setor.`
    }

    const handleSelectAll = () => {
        onSelect(TEXTS[parameterType].defaultParameterTitle, 'all', 'all')
    }

    const handleSelectSpecific = (value: string) => {
        onSelect(specificSelectedTitle, value, specificSelected)
    }

    const handleSpecificSelection = (option: DropdownOptionTypes) => {
        onSelect(hashMapTitle[option.value], 'specific', option.value)
        setSpecificSelectedTitle(hashMapTitle[option.value])
        setSpecificSelected(option.value)
        setSpecificOption({ value: option.value, label: option.label })
    }

    return (
        <MainContainer>
            <FlexContainer>
                <RowText>{TEXTS[parameterType].title}</RowText>
            </FlexContainer>
            <FlexContainerColumn>
                <FlexContainer>
                    <RowText>{TEXTS[parameterType].subtitle}</RowText>
                    <FlexContainer
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <InfoIcon />
                        {hover && (
                            <InfoContainer>{getInfoText()}</InfoContainer>
                        )}
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer>
                    <RadioButtonContainer>
                        <RadioButtonComponent
                            inputName={
                                TEXTS[parameterType].defaultParameterTitle
                            }
                            value="all"
                            selectedValue={selectedParameterType}
                            onChange={handleSelectAll}
                        />
                        <InputLabel htmlFor="id-all">
                            {TEXTS[parameterType].defaultParameterTitle}
                        </InputLabel>
                    </RadioButtonContainer>
                    <SpecifParameterContainer>
                        <RadioButtonContainer>
                            <RadioButtonComponent
                                inputName="specific"
                                value="specific"
                                selectedValue={selectedParameterType}
                                onChange={(e) => handleSelectSpecific(e)}
                            />
                        </RadioButtonContainer>
                        <DropdownContainer>
                            <BasicDropdownComponent
                                options={dropdownOptions}
                                onSelect={(option) =>
                                    handleSpecificSelection(option)
                                }
                                placeholder={TEXTS[parameterType].placeholder}
                                value={{
                                    value: specificOption.value,
                                    label: specificOption.label,
                                }}
                            />
                        </DropdownContainer>
                    </SpecifParameterContainer>
                </FlexContainer>
            </FlexContainerColumn>
        </MainContainer>
    )
}

const DropdownContainer = styled.div`
    width: 48%;
`
const FlexContainer = styled.div`
    display: flex;
`

const FlexContainerColumn = styled(FlexContainer)`
    flex-direction: column;
`

const InfoContainer = styled.div`
    background-color: ${COLORS.primaryGray};
    border-radius: 5px;
    color: ${COLORS.primaryWhite};
    font-size: 12px;
    letter-spacing: 0.1px;
    margin-top: 18px;
    opacity: 0.9;
    padding: 11px;
    position: absolute;
    width: 279px;
`

const InputLabel = styled.label`
    color: ${COLORS.primaryGray};
    font-family: Roboto;
    font-size: 14px;
    line-height: 2;
`

const MainContainer = styled(FlexContainer)`
    border: 1px solid ${COLORS.borderGray};
    border-radius: 3px;
    flex-direction: column;
    height: 120px;
    justify-content: space-between;
    margin-top: 40px;
    padding: 25px;
    width: 95%;
`

const RadioButtonContainer = styled(FlexContainer)`
    align-content: center;
    align-items: center;
`

const RowText = styled.span`
    color: ${COLORS.brownishGrey};
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
    text-transform: uppercase;
`
const SpecifParameterContainer = styled(FlexContainer)`
    margin-left: 200px;
    width: 30%;
`
