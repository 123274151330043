import { Check as CheckIcon } from '@mui/icons-material'
import { FormHelperText, InputAdornment } from '@mui/material'
import Button from '@mui/material/Button'
import { styled as materialStyled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import FormInputComponent from '../../commons/components/FormInputComponent'
import LoadingComponent from '../../commons/components/LoadingComponent'
import MainHeaderComponent from '../../commons/components/MainHeaderComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import SwitchButtonComponent from '../../commons/components/SwitchButtonComponent'
import {
    formatCurrency,
    removeCurrencyFormat,
} from '../../commons/utils/Currency'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import GlobalThemeProvider from '../../style/GlobalTheme'
import { ConfigForm, MillForm, TypeForm } from '../Utils'

type MillManagementFormViewProps = {
    millForm: MillForm
    configForm: ConfigForm
    handleFormChange: (
        form: TypeForm,
        field: string,
        value: string | number | boolean
    ) => void
    avaliableUfs: string[]
    handleSave: () => Promise<void>
    validateFields: () => boolean
    isEdition: boolean
    snackbarConfig: SnackbarConfigTypes
    handleCloseSnackbar: () => void
    loading: boolean
}

function MillManagementFormView({
    millForm,
    configForm,
    handleFormChange,
    avaliableUfs,
    handleSave,
    validateFields,
    isEdition,
    snackbarConfig,
    handleCloseSnackbar,
    loading = false,
}: MillManagementFormViewProps): JSX.Element {
    return (
        <GlobalThemeProvider>
            <PageContainer>
                <MainHeaderComponent />
                <PageTitle>
                    {isEdition ? 'Editar Fábrica' : 'Nova Fábrica'}
                </PageTitle>
                <FormContainer>
                    <FormHeaderContainer>
                        <FormHelperText>
                            *Informações obrigatórias
                        </FormHelperText>
                    </FormHeaderContainer>
                    <MillFormContainer>
                        <FormInputComponent
                            label="* Nome Fantasia"
                            testid="form-input-name"
                            required
                            value={millForm.name}
                            onChange={(e: any) =>
                                handleFormChange(
                                    TypeForm.Mill,
                                    'name',
                                    e.target.value
                                )
                            }
                            placeholder="Nome fantasia"
                            width={466}
                        />
                        <FormInputComponent
                            label="Razão Social"
                            testid="form-input-corporateName"
                            value={millForm.corporateName}
                            onChange={(e: any) =>
                                handleFormChange(
                                    TypeForm.Mill,
                                    'corporateName',
                                    e.target.value
                                )
                            }
                            placeholder="Razão social"
                            width={466}
                        />
                        <FormInputComponent
                            label="Código"
                            testid="form-input-legacyCode"
                            value={millForm.legacyCode}
                            onChange={(e: any) =>
                                handleFormChange(
                                    TypeForm.Mill,
                                    'legacyCode',
                                    e.target.value
                                )
                            }
                            placeholder="Código"
                            width={113}
                        />
                        <FormInputComponent
                            label="UF"
                            testid="form-input-uf"
                            value={millForm.uf}
                            onChange={(e: any) =>
                                handleFormChange(
                                    TypeForm.Mill,
                                    'uf',
                                    e.target.value
                                )
                            }
                            placeholder="UF"
                            width={113}
                            select
                            selectOptions={avaliableUfs}
                        />
                    </MillFormContainer>
                </FormContainer>

                <ConfigContainer>
                    <ConfigLabel formLabel>Permissões de fábrica</ConfigLabel>
                    <ConfigRow>
                        <ConfigItem>
                            <ConfigLabel>
                                EdiTAR pedidos Na plataforma
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="form-input-allowsOrderEdition"
                                active={configForm.allowsOrderEdition}
                                onClick={(isActive) =>
                                    handleFormChange(
                                        TypeForm.Config,
                                        'allowsOrderEdition',
                                        isActive
                                    )
                                }
                            />
                            <ConfigDescription>
                                Habilita a edição de pedidos na Plataforma, de
                                forma que pedidos enviados pelos vendedores
                                possam ser alterados por usuários habilitados no
                                portal.
                            </ConfigDescription>
                        </ConfigItem>
                        <ConfigItem>
                            <ConfigLabel>
                                utilizaR chapa (carregador) nos pedidos
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="form-input-useChapa"
                                active={configForm.useChapa}
                                onClick={(isActive) =>
                                    handleFormChange(
                                        TypeForm.Config,
                                        'useChapa',
                                        isActive
                                    )
                                }
                            />
                            <ConfigDescription>
                                Habilita a opção de incluir chapa (pessoa
                                contratada para descarregar as cargas) nos
                                pedidos realizados via Plataforma, de forma que
                                ao lançar um novo pedido seja possível incluir
                                este tipo de serviço.
                            </ConfigDescription>
                        </ConfigItem>
                    </ConfigRow>
                    <ConfigRow>
                        <ConfigItem>
                            <ConfigLabel>
                                digitar o valor cobrado por tonelada pelo chapa
                                (carregador)
                            </ConfigLabel>
                            <FormInputComponent
                                label="Valor do serviço de chapa por tonelada"
                                startAdornment={
                                    <FormInputAdornment position="start">
                                        R$
                                    </FormInputAdornment>
                                }
                                testid="form-input-chapaValue"
                                value={formatCurrency(configForm.chapaValue!)}
                                onChange={(e: any) =>
                                    handleFormChange(
                                        TypeForm.Config,
                                        'chapaValue',
                                        removeCurrencyFormat(e.target.value)
                                    )
                                }
                                suffix="TON"
                                inputProps={{ maxLength: 22 }}
                            />
                            <ConfigDescription>
                                Permite customizar o valor cobrado na tonelada
                                pelo serviço de chapa nos pedidos realizados via
                                plataforma.
                            </ConfigDescription>
                        </ConfigItem>
                        <ConfigItem>
                            <ConfigLabel>
                                UTILIZAR pallet nos pedidos
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="form-input-usePallet"
                                active={configForm.usePallet}
                                onClick={(isActive) =>
                                    handleFormChange(
                                        TypeForm.Config,
                                        'usePallet',
                                        isActive
                                    )
                                }
                            />
                            <ConfigDescription>
                                Habilita a opção de incluir pallet (suporte de
                                madeira para elevação das cargas) nos pedidos
                                realizados via Plataforma, de forma que ao
                                lançar um novo pedido seja possível incluir este
                                tipo de serviço.
                            </ConfigDescription>
                        </ConfigItem>
                    </ConfigRow>
                    <ConfigRow>
                        <ConfigItem>
                            <ConfigLabel>
                                permitir pedidos fora da política de preços
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="form-input-allowsOrderOutOfPricePolicy"
                                active={configForm.allowsOrderOutOfPricePolicy}
                                onClick={(isActive) =>
                                    handleFormChange(
                                        TypeForm.Config,
                                        'allowsOrderOutOfPricePolicy',
                                        isActive
                                    )
                                }
                            />
                            <ConfigDescription>
                                Habilita a permissão de emissão de pedidos que
                                estejam fora da política de preços (o campo pode
                                ser preenchido por valores acima ou abaixo da
                                mesma, facilitando a oferta de descontos, por
                                exemplo).
                            </ConfigDescription>
                        </ConfigItem>
                        <ConfigItem>
                            <ConfigLabel>
                                GERENCIAR estoque de segurança de fábrica
                            </ConfigLabel>
                            <FormInputComponent
                                label="Estoque de Segurança (dias)"
                                testid="form-input-stockSafetyDays"
                                value={configForm.stockSafetyDays || ''}
                                onChange={(e: any) => {
                                    const formattedValue =
                                        e.target.value.replace(/\D/g, '')
                                    const parsedValue = parseInt(
                                        formattedValue,
                                        10
                                    )
                                    const numberValue = Number.isNaN(
                                        parsedValue
                                    )
                                        ? 0
                                        : parsedValue
                                    handleFormChange(
                                        TypeForm.Config,
                                        'stockSafetyDays',
                                        numberValue
                                    )
                                }}
                                suffix="DIAS"
                                placeholder="Nº de dias"
                            />
                            <ConfigDescription>
                                Gerencia a opção de Estoque de Segurança na
                                plataforma, mitigando o desabastecimento do
                                cliente.
                            </ConfigDescription>
                        </ConfigItem>
                    </ConfigRow>
                    <ConfigRow>
                        <ConfigItem>
                            <ConfigLabel>
                                permitir prazo de pagamento customizado
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="form-input-allowsCustomDeadline"
                                active={configForm.allowsCustomDeadline}
                                onClick={(isActive) =>
                                    handleFormChange(
                                        TypeForm.Config,
                                        'allowsCustomDeadline',
                                        isActive
                                    )
                                }
                            />
                            <ConfigDescription>
                                Permite customizar o prazo de pagamento durante
                                a emissão de pedidos.
                            </ConfigDescription>
                        </ConfigItem>
                        <EmptyItem />
                    </ConfigRow>
                </ConfigContainer>

                <FormButtonsContainer>
                    <Link to="/gestao/fabrica">
                        <CancelButton variant="outlined" disabled={loading}>
                            Cancelar
                        </CancelButton>
                    </Link>
                    <SaveButton
                        startIcon={loading && <LoadingComponent size="small" />}
                        onClick={handleSave}
                        disabled={!validateFields() || loading}
                        endIcon={<CheckIcon />}
                    >
                        Salvar
                    </SaveButton>
                </FormButtonsContainer>

                <SnackBarComponent
                    handleClose={handleCloseSnackbar}
                    isVisible={snackbarConfig.isVisible}
                    message={snackbarConfig.message}
                    severity={snackbarConfig.status}
                />
            </PageContainer>
        </GlobalThemeProvider>
    )
}

export default MillManagementFormView

const FormInputAdornment = materialStyled(InputAdornment)(() => ({
    '& .MuiTypography-root': {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.25,
    },
}))

const SaveButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    backgroundColor: COLORS.primaryGreen,
    borderColor: COLORS.primaryGreen,
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    '&:hover': {
        backgroundColor: COLORS.secondaryGreen,
        borderColor: COLORS.secondaryGreen,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
})

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.secondaryWhite};
`
const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    margin: 11px 32px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 3px;
    padding: 28px 23px 23px 22px;
    background-color: ${COLORS.primaryWhite};
`
const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    font-weight: normal;
    padding: 24px;
`
const FormHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.mainHeaderText};
    margin: 0 0 20px;
`

const FormButtonsContainer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    justify-content: end;
    gap: 16px;
    align-items: center;
    padding: 0 32px 30px;
    margin-top: 9px;
`
const MillFormContainer = styled.form`
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 28px 23px 23px 22px;
    border-radius: 3px;
    border: solid 1px ${COLORS.borderGray};
`
const ConfigContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    margin: 11px 32px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 3px;
    padding: 28px 23px 23px 22px;
    gap: 16px;
    background-color: ${COLORS.primaryWhite};
`
const ConfigRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`
const ConfigItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 16px;
    flex-grow: 0;
    padding: 15px 23px 26px;
    border-radius: 3px;
    border: solid 1px ${COLORS.borderGray};
`
const EmptyItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 16px;
    flex-grow: 0;
    padding: 15px 23px 26px;
`

const ConfigLabel = styled.span<{
    formLabel?: boolean
}>`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
    text-transform: uppercase;
    ${({ formLabel }) =>
        formLabel &&
        `
        margin: 0px 0px 2px;
    `}
`

const ConfigDescription = styled.span`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
`
