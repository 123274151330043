import moment from 'moment'

import { makeLocalStorageService } from '../commons/utils/LocalStorageService'
import { ILocalStorageService, StorageKeys } from './ILocalStorageService'

export type TokenFabs = {
    accessToken: {
        token: string
        payload: {
            id: string
            name: string
            login: string
            exp: number
            iat: number
        }
    }
    mill?: {
        id: string
        name: string
    }
}

export class Auth {
    private currentDate = Date.now().valueOf() / 1000
    private expirationDate = 0
    private isAuthenticated = false
    private localStorageService: ILocalStorageService

    constructor(
        {
            localStorageService,
        }: {
            localStorageService: ILocalStorageService
        } = { localStorageService: makeLocalStorageService() }
    ) {
        this.localStorageService = localStorageService

        const tokenFabs = localStorageService.getItem<TokenFabs>(
            StorageKeys.USERTOKEN
        )

        if (!tokenFabs) return

        this.expirationDate = tokenFabs.accessToken.payload.exp
        this.isAuthenticated = this.isValidToken()

        if (!this.isAuthenticated)
            localStorageService.removeItem(StorageKeys.USERTOKEN)
    }

    private isValidToken(): boolean {
        try {
            return !this.isTokenExpired()
        } catch (err) {
            const error = err as Error
            console.error(error.message)
            return false
        }
    }

    private isTokenExpired(): boolean {
        const hoursToExpire = moment
            .duration(
                (this.expirationDate - this.currentDate) * 1000,
                'milliseconds'
            )
            .asHours()

        return hoursToExpire < 0
    }

    public getIsAuthenticated(): boolean {
        if (this.isAuthenticated) return this.isAuthenticated

        const tokenFabs = this.localStorageService.getItem<TokenFabs>(
            StorageKeys.USERTOKEN
        )

        if (!this.isAuthenticated && !tokenFabs) return this.isAuthenticated

        this.expirationDate = tokenFabs.accessToken.payload.exp
        this.isAuthenticated = this.isValidToken()

        return this.isAuthenticated
    }
}
