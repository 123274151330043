import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../commons/Colors'
import SnackBarComponent from '../../../commons/components/SnackBarComponent'
import { SnackbarConfigTypes } from '../../../commons/utils/Types'
import { AvailableItem } from '../../../models/AvailableItem'
import { ParameterOption } from '../../../models/ParameterOption'
import { PermissionConfig } from '../../../models/PermissionConfig'
import Footer from './Footer'
import ParameterOptions from './ParameterOptions'
import SelectionBox from './SelectionBox'

type CustomConfigClientProfileProps = {
    availableItems: AvailableItem[]
    configItems: PermissionConfig[]
    initialConfigItems: PermissionConfig[]
    parameters: ParameterOption[]
    parameterType:
        | 'paymentTerms'
        | 'segmentPlants'
        | 'statesConfig'
        | 'salesChannel'
    isLoading: boolean
    configLoadingFailed: boolean
    onCancel: (configs: PermissionConfig[]) => void
    onChangeConfigItems: Dispatch<SetStateAction<PermissionConfig[]>>
    onChangeSelectedParameterValue: Dispatch<SetStateAction<string>>
    onSave: () => Promise<void>
}

const TEXTS = {
    paymentTerms: {
        title: 'Setor Industrial',
        defaultParameterTitle: 'PADRÃO PARA TODOS OS SETORES',
    },
    segmentPlants: {
        title: 'PLANTA',
        defaultParameterTitle: 'PADRÃO PARA TODAS AS PLANTAS',
    },
    statesConfig: {
        title: 'ESTADO',
        defaultParameterTitle: 'PADRÃO PARA TODOS OS ESTADOS',
    },
    salesChannel: {
        title: 'Setor Industrial',
        defaultParameterTitle: 'PADRÃO PARA TODOS OS SETORES',
    },
}

export default function CustomConfigClientProfile({
    availableItems,
    configItems,
    initialConfigItems,
    parameters,
    parameterType,
    isLoading,
    configLoadingFailed,
    onCancel,
    onChangeConfigItems,
    onChangeSelectedParameterValue,
    onSave,
}: CustomConfigClientProfileProps) {
    const [selectedParameterType, setSelectedParameterType] =
        useState<string>('all')
    const [selectedParameterTitle, setSelectedParameterTitle] =
        useState<string>(TEXTS[parameterType].defaultParameterTitle)
    const [isConfigSaved, setIsConfigSaved] = useState<boolean>(false)
    const [snackbarConfig, setSnackBarConfig] = useState<SnackbarConfigTypes>({
        message: '',
        isVisible: false,
        status: 'success',
    })
    const [isSaving, setIsSaving] = useState<boolean>(false)

    useEffect(() => {
        if (configLoadingFailed)
            setSnackBarConfig({
                message:
                    'Ocorreu um erro, por favor tente novamente mais tarde!',
                isVisible: true,
                status: 'error',
            })
    }, [configLoadingFailed])

    const handleSelectParameter = (
        title: string,
        type: string,
        value: string
    ) => {
        onChangeSelectedParameterValue(value)
        setSelectedParameterTitle(title)
        setSelectedParameterType(type)
    }

    const handleCloseSnackbar = () => {
        setSnackBarConfig((prev) => ({ ...prev, isVisible: false }))
    }

    const handleSave = async () => {
        try {
            setIsSaving(true)
            await onSave()
            setIsConfigSaved((current) => !current)
            setSnackBarConfig({
                message: 'Customização salva com sucesso!',
                isVisible: true,
                status: 'success',
            })
        } catch (err) {
            console.error(err)
            setSnackBarConfig({
                message:
                    'Ocorreu um erro, por favor tente novamente mais tarde!',
                isVisible: true,
                status: 'error',
            })
        } finally {
            setIsSaving(false)
        }
    }

    return (
        <MainContainer>
            <ParameterOptions
                parameters={parameters}
                parameterType={parameterType}
                selectedParameterType={selectedParameterType}
                onSelect={handleSelectParameter}
            />
            <Title>{`${TEXTS[parameterType].title}: ${selectedParameterTitle}`}</Title>
            <SelectionBox
                isLoading={isLoading}
                parameterType={parameterType}
                configItems={configItems}
                availableItems={availableItems}
                onChangeConfigItems={onChangeConfigItems}
                isConfigSaved={isConfigSaved}
            />
            <Footer
                configItems={configItems}
                initialConfigItems={initialConfigItems}
                onSave={handleSave}
                onCancel={onCancel}
                isSaving={isSaving}
            />
            <SnackBarComponent
                severity={snackbarConfig.status}
                isVisible={snackbarConfig.isVisible}
                message={snackbarConfig.message}
                showTimeInMs={3000}
                handleClose={handleCloseSnackbar}
            />
        </MainContainer>
    )
}

const MainContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`

const Title = styled.span`
    color: ${COLORS.brownishGrey};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.33px;
    margin: 40px;
`
