import { Navigate } from 'react-router-dom'

import { Auth } from '../../models/Auth'

export type PrivateRouteProps = {
    auth: Auth
    redirectPath?: string
    component: JSX.Element
}

function PrivateRoute({
    auth: Auth,
    redirectPath = '/login',
    component,
}: PrivateRouteProps): JSX.Element {
    const isAuthenticated = Auth.getIsAuthenticated()

    if (!isAuthenticated) {
        return <Navigate to={redirectPath} />
    }

    return component
}

export default PrivateRoute
