import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import LoginScreenImage from '../assets/images/LoginScreenImage.png'
import FarmTellMillsWhiteLogo from '../assets/logo/FarmTellMillsWhiteLogo.png'
import LoadingComponent from '../commons/components/LoadingComponent'
import SnackBarComponent from '../commons/components/SnackBarComponent'
import { ROUTES } from '../commons/utils/AppRoutes'
import { makeLocalStorageService } from '../commons/utils/LocalStorageService'
import { useUserContext } from '../context/UserContext'
import { StorageKeys } from '../models/ILocalStorageService'
import * as LoginAPI from './API/LoginAPI'

type LoginPayload = {
    login: string
    password: string
}

const allowedUsers = [
    { login: 'fabs.dev' },
    { login: 'fabs.qa' },
    { login: 'dev' },
    { login: 'aplicativo.demo' },
    { login: 'plataforma.demo' },
    { login: 'fabs.dsm.dev' },
    { login: 'fabs.dsm.qa' },
    { login: 'plataforma.dsm.demo' },
]

function Login(): JSX.Element {
    const navigate = useNavigate()
    const { loadUser } = useUserContext()
    const [authStatus, setAuthStatus] = useState<{ isError: boolean }>({
        isError: false,
    })
    const [loginPayload, setLoginPayload] = useState<LoginPayload>({
        login: '',
        password: '',
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { setItem } = makeLocalStorageService()

    const handleLoginPayloadChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target
        setLoginPayload((payload) => ({
            ...payload,
            [name]: value,
        }))
    }

    const signIn = async ({ login, password }: LoginPayload) => {
        setIsLoading(true)
        try {
            const isAllowedUser = allowedUsers.some(
                (allowedUser) => allowedUser.login === login
            )

            if (!isAllowedUser) {
                setAuthStatus({ isError: true })
                setIsLoading(false)
                return
            }

            const response = await LoginAPI.signIn({ login, password })

            if (response && response.accessToken) {
                setItem(StorageKeys.USERTOKEN, response)
                await loadUser()
                setAuthStatus({ isError: false })
                navigate(ROUTES.HOME)
            } else {
                setAuthStatus({ isError: true })
            }
        } catch (err) {
            const error = err as Error
            console.error('Erro ao tentar fazer login: ', error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const isButtonDisable = (): boolean => {
        return !(
            loginPayload.login.length > 1 && loginPayload.password.length > 1
        )
    }

    return (
        <Container>
            <ContainerWhite>
                <Title>
                    <span>BEM VINDO AO PORTAL</span>
                    <span>ADMINISTRATIVO</span>
                    <span>FARMTELL MILLS</span>
                </Title>
                <Logo src={LoginScreenImage} />
            </ContainerWhite>
            <ContainerOrange>
                <Form
                    onSubmit={async (event) => {
                        event.preventDefault()
                        await signIn(loginPayload)
                    }}
                >
                    <FarmTellMillsLogo src={FarmTellMillsWhiteLogo} />
                    <InputContainer>
                        <InputLabel>USUÁRIO</InputLabel>
                        <Input
                            type="text"
                            name="login"
                            placeholder="Insira Usuário"
                            value={loginPayload.login}
                            onChange={handleLoginPayloadChange}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputLabel>SENHA</InputLabel>
                        <Input
                            type="password"
                            name="password"
                            placeholder="Insira Senha"
                            autoComplete="on"
                            value={loginPayload.password}
                            onChange={handleLoginPayloadChange}
                        />
                    </InputContainer>
                    <LoginButon disabled={isButtonDisable()}>
                        {isLoading ? 'Carregando...' : 'ENTRAR'}
                    </LoginButon>
                    {isLoading && <LoadingComponent size="small" />}
                </Form>
            </ContainerOrange>
            {authStatus.isError && (
                <SnackBarComponent
                    severity="error"
                    message="Usuário e/ou Senha inválidos!"
                    isVisible
                    handleClose={() => setAuthStatus({ isError: false })}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`

const ContainerOrange = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 45vw;
    background-color: #f08b1f;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6vh;
    height: auto;
    width: 60%;
`

const FarmTellMillsLogo = styled.img`
    width: 10vw;
    min-width: 170px;
    height: auto;
    margin-bottom: 7vh;
`
const InputContainer = styled.div`
    width: 56%;
    height: 30%;
    margin-bottom: 3vh;
`
const InputLabel = styled.label`
    font-family: Roboto;
    width: 20.64vw;
    height: 10px;
    font-size: 12px;
    line-height: 3;
    letter-spacing: 0.09px;
    color: #fff;
    padding: 2px 2px 2px 2px;
`
const Input = styled.input`
    width: 100%;
    height: 1.5vh;
    padding: 10px 9px;
    border-radius: 4px;
    border: solid 1px #c7c7cc;
    color: #8e8e93;
    background-color: #fff;
    font-family: Roboto;
`
const LoginButon = styled.button.attrs(() => ({ type: 'submit' }))`
    width: 58%;
    min-width: 100px;
    height: 7.3vh;
    border-radius: 30px;
    font-family: Roboto;
    letter-spacing: 0.8px;
    font-size: 90%;
    margin-top: 4vh;
    margin-left: 2vh;
    color: #fff;
    border: solid 1px #48a2cf;
    background-color: #48a2cf;
    :disabled {
        background-color: #f08b1f;
        border-color: #fff;
    }
`

const ContainerWhite = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 55vw;
    background-color: #ffffff;
`
const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45vh;
    height: auto;
    font-family: Roboto;
    font-size: 1.8vw;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: 1.35px;
    text-align: center;
    color: #0b3c56;
    margin-bottom: 15vh;
`
const Logo = styled.img`
    width: 50%;
    min-width: 200px;
    height: auto;
`

export default Login
