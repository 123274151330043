import Skeleton from '@mui/material/Skeleton'
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react'
import styled from 'styled-components'

import CancelIcon from '../../../assets/images/cancel.png'
import { COLORS } from '../../../commons/Colors'
import CheckboxComponent from '../../../commons/components/CheckboxComponent'
import SwitchButtonComponent from '../../../commons/components/SwitchButtonComponent'
import { AvailableItem } from '../../../models/AvailableItem'
import { PermissionConfig } from '../../../models/PermissionConfig'

type SelectionBoxProps = {
    availableItems: AvailableItem[]
    configItems: PermissionConfig[]
    parameterType:
        | 'paymentTerms'
        | 'segmentPlants'
        | 'statesConfig'
        | 'salesChannel'
    isConfigSaved: boolean
    isLoading: boolean
    onChangeConfigItems: Dispatch<SetStateAction<PermissionConfig[]>>
}

export default function SelectionBox({
    availableItems,
    configItems,
    parameterType,
    isConfigSaved,
    isLoading,
    onChangeConfigItems,
}: SelectionBoxProps) {
    const [enableSwitchAllSelectedItems, setEnableSwitchAllSelectedItems] =
        useState<boolean>(false)
    const [filteredAvailableItems, setFilteredAvailableItems] = useState<
        AvailableItem[]
    >([])
    const [filteredSelectedItems, setFilteredSelectedItems] = useState<
        PermissionConfig[]
    >([])
    const [selectAllAvailableItems, setSelectAllAvailableItems] =
        useState<boolean>(false)
    const [selectAllSelectedItems, setSelectAllSelectedItems] =
        useState<boolean>(false)
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set())
    const [selectedItemsChecked, setSelectedItemsChecked] = useState<
        Set<string>
    >(new Set())
    const [searchedValue, setSearchedValue] = useState<string>('')

    const hashMapItemsName = availableItems.reduce(
        (acc, item) => ({ ...acc, [item.id]: item.name }),
        {}
    ) as any

    useEffect(() => {
        const setOfItems = new Set() as Set<string>

        configItems.forEach((config) => {
            setOfItems.add(config.item)
        })

        setSelectedItems(setOfItems)
        setFilteredSelectedItems(
            configItems.filter((item) => {
                if (hashMapItemsName[item.item]) {
                    return hashMapItemsName[item.item]
                        .toUpperCase()
                        .includes(searchedValue.toLocaleUpperCase())
                }
                return true
            })
        )
    }, [configItems])

    useEffect(() => {
        setFilteredAvailableItems(availableItems)
    }, [availableItems])

    useEffect(() => {
        setSelectAllAvailableItems(false)
        setSelectAllSelectedItems(false)
    }, [isConfigSaved])

    const handleChangeFilter = (searchedValue: string): void => {
        setSearchedValue(searchedValue)
        setFilteredAvailableItems(
            availableItems.filter(({ name }) =>
                name.toUpperCase().includes(searchedValue.toLocaleUpperCase())
            )
        )
        setFilteredSelectedItems(
            configItems.filter((item) =>
                hashMapItemsName[item.item]
                    .toUpperCase()
                    .includes(searchedValue.toLocaleUpperCase())
            )
        )
    }

    const handleCheckAllSelectedItems = (): void => {
        if (!selectAllSelectedItems) {
            const setOfSelectedItems = new Set() as Set<string>

            filteredSelectedItems.forEach((config) => {
                setOfSelectedItems.add(config.item)
            })

            setSelectedItemsChecked(setOfSelectedItems)
        } else {
            setSelectedItemsChecked(new Set())
            setEnableSwitchAllSelectedItems(false)
        }

        setSelectAllSelectedItems((bool) => !bool)
    }

    const handleCheckSelectedItem = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = event.target
        const newSet = new Set(selectedItemsChecked)

        if (selectedItemsChecked.has(value)) {
            if (selectedItemsChecked.size === 1)
                setSelectAllSelectedItems(false)

            newSet.delete(value)
            setSelectedItemsChecked(newSet)
        } else {
            if (selectedItemsChecked.size === 0) setSelectAllSelectedItems(true)

            newSet.add(value)
            setSelectedItemsChecked(newSet)
        }
    }

    const handleDeleteAllSelectedItems = (): void => {
        if (selectAllSelectedItems) {
            onChangeConfigItems(
                configItems.filter(
                    ({ item }) => !selectedItemsChecked.has(item)
                )
            )
        }
    }

    const handleDeleteSelectedItem = (item: string): void => {
        onChangeConfigItems(
            configItems.filter((config) => item !== config.item)
        )
    }

    const handleEnableSelectedItems = (): void => {
        if (selectAllSelectedItems && enableSwitchAllSelectedItems) {
            onChangeConfigItems(
                configItems.map((config) => {
                    if (selectedItemsChecked.has(config.item)) {
                        return { ...config, active: false }
                    }
                    return config
                })
            )
        }

        if (selectAllSelectedItems && !enableSwitchAllSelectedItems) {
            onChangeConfigItems(
                configItems.map((config) => {
                    if (selectedItemsChecked.has(config.item)) {
                        return { ...config, active: true }
                    }
                    return config
                })
            )
        }

        setEnableSwitchAllSelectedItems((bool) => !bool)
    }

    const handleEnableSelectedItem = (item: string): void => {
        onChangeConfigItems(
            configItems.map((config) => {
                if (item === config.item) {
                    return { ...config, active: !config.active }
                }
                return config
            })
        )
    }

    const handleSelectAllAvailableItems = (): void => {
        if (selectAllAvailableItems) onChangeConfigItems([])
        else
            onChangeConfigItems(
                availableItems.map(({ id }) => ({ item: id, active: true }))
            )

        setSelectAllAvailableItems((bool) => !bool)
    }

    const handleSelectAvailableItem = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = event.target
        if (selectedItems.has(value))
            onChangeConfigItems(
                configItems.filter(({ item }) => item !== value)
            )
        else
            onChangeConfigItems([...configItems, { item: value, active: true }])
    }

    return (
        <MainContainer>
            <FilterContainer>
                <LabelField htmlFor="search-input-component">Filtro</LabelField>
                <InputField
                    id="search-input-component"
                    placeholder={
                        parameterType !== 'paymentTerms'
                            ? 'Filtrar por produto'
                            : 'Filtrar por condição'
                    }
                    onChange={(e) => handleChangeFilter(e.target.value)}
                />
            </FilterContainer>
            <BoxContainer>
                {isLoading ? (
                    <>
                        <Skeleton
                            variant="rounded"
                            width="49%"
                            height={450}
                            animation="wave"
                        />

                        <Skeleton
                            variant="rounded"
                            width="49%"
                            height={450}
                            animation="wave"
                        />
                    </>
                ) : (
                    <>
                        <FlexColumnContainer>
                            <BoxTitle>
                                {parameterType !== 'paymentTerms'
                                    ? '*PRODUTOS DISPONÍVEIS'
                                    : '*CONDIÇÕES DISPONÍVEIS'}
                            </BoxTitle>
                            <Box>
                                <HeaderItemContainer>
                                    <CheckboxComponent
                                        checked={selectAllAvailableItems}
                                        onChange={handleSelectAllAvailableItems}
                                        value="all"
                                    />
                                    <Text>
                                        {parameterType !== 'paymentTerms'
                                            ? 'SELECIONAR TODOS OS PRODUTOS'
                                            : 'SELECIONAR TODAS AS CONDIÇÕES'}
                                    </Text>
                                </HeaderItemContainer>
                                <ItemsBox>
                                    {filteredAvailableItems.map((item) => (
                                        <ItemContainer key={item.id}>
                                            <CheckboxComponent
                                                checked={selectedItems.has(
                                                    item.id
                                                )}
                                                onChange={
                                                    handleSelectAvailableItem
                                                }
                                                value={item.id}
                                            />
                                            <Text>{item.name}</Text>
                                        </ItemContainer>
                                    ))}
                                </ItemsBox>
                            </Box>
                        </FlexColumnContainer>
                        <FlexColumnContainer>
                            <BoxTitle>
                                {parameterType !== 'paymentTerms'
                                    ? '*PRODUTOS SELECIONADOS'
                                    : '*CONDIÇÕES SELECIONADAS'}
                            </BoxTitle>
                            <Box>
                                <HeaderItemContainer>
                                    <CheckboxComponent
                                        checked={selectAllSelectedItems}
                                        onChange={handleCheckAllSelectedItems}
                                        value="all"
                                    />
                                    <Text style={{ width: '78.9%' }}>
                                        {parameterType !== 'paymentTerms'
                                            ? 'SELECIONAR TODOS OS PRODUTOS'
                                            : 'SELECIONAR TODAS AS CONDIÇÕES'}
                                    </Text>
                                    <SwitchButtonComponent
                                        testid="all"
                                        active={enableSwitchAllSelectedItems}
                                        onClick={handleEnableSelectedItems}
                                        disabled={!selectAllSelectedItems}
                                    />
                                    <Button
                                        onClick={handleDeleteAllSelectedItems}
                                    >
                                        <Image src={CancelIcon} />
                                    </Button>
                                </HeaderItemContainer>
                                <ItemsBox>
                                    {filteredSelectedItems.map((item) => (
                                        <ItemContainer
                                            key={`${item.item}${item.active}`}
                                        >
                                            <CheckboxComponent
                                                checked={selectedItemsChecked.has(
                                                    item.item
                                                )}
                                                onChange={
                                                    handleCheckSelectedItem
                                                }
                                                value={item.item}
                                            />
                                            <Text>
                                                {hashMapItemsName[item.item]}
                                            </Text>
                                            <SwitchButtonComponent
                                                active={item.active}
                                                testid={item.item}
                                                onClick={() =>
                                                    handleEnableSelectedItem(
                                                        item.item
                                                    )
                                                }
                                            />
                                            <Button
                                                onClick={() =>
                                                    handleDeleteSelectedItem(
                                                        item.item
                                                    )
                                                }
                                            >
                                                <Image src={CancelIcon} />
                                            </Button>
                                        </ItemContainer>
                                    ))}
                                </ItemsBox>
                            </Box>
                        </FlexColumnContainer>
                    </>
                )}
            </BoxContainer>
            <InformativeText>
                Os segmentos selecionados podem ser marcados como ATIVOS ou
                INATIVOS no aplicativo, sendo determinante sua exibição ou
                ocultação aos usuário.
            </InformativeText>
        </MainContainer>
    )
}

const Box = styled.div`
    background-color: ${COLORS.primaryWhite};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    height: 440px;
    width: 100%;
`

const BoxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const BoxTitle = styled.span`
    color: ${COLORS.brownishGrey};
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 15px;
`

const Button = styled.button`
    background: transparent;
    border: none;
    border-left: 1px solid ${COLORS.lightBorderGray};
    display: flex;
    margin-left: 10px;
`

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 49%;
`

const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 49%;
`

const HeaderItemContainer = styled.div`
    background-color: ${COLORS.thirdWhite};
    display: flex;
    padding: 15px;
`

const Image = styled.img``

const InformativeText = styled.span`
    color: ${COLORS.primaryGray};
    font-size: 12px;
    margin-top: 15px;
`

const InputField = styled.input`
    border: 1px solid ${COLORS.borderGray};
    border-radius: 4px;
    font-family: Roboto;
    font-size: 14px;
    height: 30px;
    line-height: 1.43;
    padding: 8px;

    :focus {
        outline-color: ${COLORS.primaryOrange};
    }
`

const ItemContainer = styled.div`
    display: flex;
    padding: 15px;
`

const ItemsBox = styled.div`
    height: 380px;
    overflow-y: scroll;

    &::-webkit-scrollbar-thumb {
        background-color: ${COLORS.lightOrange};
        border-radius: 10px;
    }
`

const LabelField = styled.label`
    color: ${COLORS.primaryGray};
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 5px;
    text-transform: uppercase;
`

const MainContainer = styled.div`
    border: 1px solid ${COLORS.borderGray};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 610px;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 25px;
    width: 95%;
`

const Text = styled.span`
    align-items: center;
    color: ${COLORS.brownishGrey};
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    width: 80%;
`
