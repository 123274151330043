import styled, { keyframes, css } from 'styled-components'

import { COLORS } from '../Colors'
import { SizeTypes } from '../utils/Types'

type LoadingComponentProps = {
    size?: SizeTypes
}

const LoadingComponent = ({
    size = 'large',
}: LoadingComponentProps): JSX.Element => {
    return (
        <LoadingContainer>
            <Spinner size={size} data-testid="loading-component" />
        </LoadingContainer>
    )
}

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const sizes = {
    small: {
        width: '13px',
        height: '13px',
        borderWidth: '2px',
    },
    medium: {
        width: '25px',
        height: '25px',
        borderWidth: '4px',
    },
    large: {
        width: '40px',
        height: '40px',
        borderWidth: '5px',
    },
}

const Spinner = styled.div<{ size: SizeTypes }>`
    ${({ size }) => css`
        border: ${sizes[size].borderWidth} solid ${COLORS.borderGray};
        border-top: ${sizes[size].borderWidth} solid ${COLORS.primaryOrange};
        width: ${sizes[size].width};
        height: ${sizes[size].height};
        border-radius: 50%;
        animation: ${spin} 2s linear infinite;
    `}
`

const LoadingContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
`

export default LoadingComponent
