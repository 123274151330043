import { createTheme, ThemeProvider } from '@mui/material/styles'

import { COLORS } from '../commons/Colors'

const GlobalTheme = createTheme({
    palette: {
        primary: { main: COLORS.primaryOrange },
        secondary: { main: COLORS.primaryBlue },
        success: {
            main: COLORS.snackBarSuccess,
        },
        error: {
            main: COLORS.error,
        },
        warning: {
            main: COLORS.warning,
        },
    },

    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    width: '90vw',
                    borderRadius: '30px',
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: '121px',
                    borderRadius: '24px',
                    minHeight: '32px',
                },
            },
        },
    },
})

type GlobalThemeProviderProps = {
    children: React.ReactNode | JSX.Element
}

function GlobalThemeProvider({
    children,
}: GlobalThemeProviderProps): JSX.Element {
    return <ThemeProvider theme={GlobalTheme}>{children}</ThemeProvider>
}

export default GlobalThemeProvider
