import { getBaseURL, getAPIKey, getOrigin } from '../../environment'
import { ISource } from '../../models/ISource'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const origin = getOrigin()
const endpoint = '/news/sources'

export const getAllSources = (): Promise<{ data: ISource[] }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
