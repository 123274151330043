import { ISource } from '../../models/ISource'

export type MountedSource = {
    value: { name: string; id: string }
    label: string
}

export const mountSourceOptions = (sources: ISource[]): MountedSource[] => {
    return sources.map((source) => {
        return {
            value: { ...source },
            label: source.name === 'Portal' ? 'Portal' : 'Aplicativo',
        }
    })
}
