import { useEffect, useState } from 'react'

import { makeLocalStorageService } from '../../commons/utils/LocalStorageService'
import { AvailableItem } from '../../models/AvailableItem'
import { StorageKeys } from '../../models/ILocalStorageService'
import { ParameterOptions } from '../../models/ParameterOption'
import { UserToken } from '../../models/UserToken'
import {
    getProducts,
    getParameterOptions,
    getPaymentForms,
} from '../AppPermissionsAPI'
import CustomerProfileView from './CustomerProfileView'

export default function CustomerProfileContainer() {
    const [availableItems, setAvailableItems] = useState<AvailableItem[]>([])
    const [availablePaymentTerms, setAvailablePaymentTerms] = useState<
        AvailableItem[]
    >([])
    const [parameterOptions, setParameterOptions] = useState<ParameterOptions>({
        salesChannels: [],
        plants: [],
        states: [],
    })
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)

    const { getItem } = makeLocalStorageService()

    useEffect(() => {
        const loadingData = async () => {
            try {
                const user = getItem(StorageKeys.USERTOKEN) as UserToken

                const [products, options, payments] = await Promise.all([
                    getProducts(
                        user.mill.id,
                        user.accessToken.token,
                        user.accessToken.payload.id
                    ),
                    getParameterOptions(user.accessToken.token),
                    getPaymentForms(
                        user.mill.id,
                        user.accessToken.token,
                        user.accessToken.payload.id
                    ),
                ])
                setAvailableItems(products.data)
                setParameterOptions(options.data)
                setAvailablePaymentTerms(payments.data)
            } catch (error) {
                setIsSnackbarOpen(true)
                console.error(
                    `Erro ao carregar dados para configurações. ${error}`
                )
            }
        }

        loadingData()
    }, [])

    return (
        <CustomerProfileView
            availableItems={availableItems}
            availablePaymentTerms={availablePaymentTerms}
            parameterOptions={parameterOptions}
            isSnackbarOpen={isSnackbarOpen}
            handleCloseSnackbar={setIsSnackbarOpen}
        />
    )
}
