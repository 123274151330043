import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../Colors'

export type SearchInputComponentProps = {
    label: string
    placeholderText: string
    data: any[]
    filterKeys: string[]
    getSearchedData: (filteredData: any[]) => void
}

function SearchInputComponent({
    label,
    placeholderText,
    data,
    filterKeys,
    getSearchedData,
}: SearchInputComponentProps): JSX.Element {
    const [inputValue, setInputValue] = useState<string>('')

    const escapeRegExp = (value: string) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    }

    useEffect(() => {
        handleFilterChange(inputValue)
    }, [inputValue])

    const handleFilterChange = (value: string): void => {
        const searchRegex = new RegExp(escapeRegExp(value), 'i')

        const filteredData = data.filter((data) => {
            return filterKeys.some((key) => searchRegex.test(data[key]))
        })

        getSearchedData(filteredData)
    }

    return (
        <SearchInputContainer>
            <LabelField htmlFor="search-input-component">{label}</LabelField>
            <InputField
                id="search-input-component"
                placeholder={placeholderText}
                onChange={({ target: { value } }) => setInputValue(value)}
                value={inputValue}
            />
        </SearchInputContainer>
    )
}

const SearchInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const LabelField = styled.label`
    text-transform: uppercase;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: ${COLORS.primaryGray};
    line-height: 2;
    margin-bottom: 5px;
`

const InputField = styled.input`
    font-family: Roboto;
    height: 30px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    line-height: 1.43;
    :focus {
        outline-color: ${COLORS.primaryOrange};
    }
`

export default SearchInputComponent
