import { Check as CheckIcon } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { styled as materialStyled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import CustomDatePicker from '../../commons/components/CustomDatePicker'
import LoadingComponent from '../../commons/components/LoadingComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import SwitchButtonComponent from '../../commons/components/SwitchButtonComponent'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import { BillingDateConfig } from '../../models/BillingDateConfig'
import GlobalThemeProvider from '../../style/GlobalTheme'
import { HandleConfigDay } from './BillingDateConfigContainer'

type BillingDateConfigViewProps = {
    billingDateConfig: BillingDateConfig
    handleAcceptImmediate: (a: boolean) => void
    handleSaveConfig: () => void
    handleCloseSnackbar: () => void
    snackbarConfig: SnackbarConfigTypes
    handleConfigDay: (params: HandleConfigDay) => void
    isLoadingConfig: boolean
    isSavingConfig: boolean
}

export default function BillingDateConfigView({
    billingDateConfig,
    handleAcceptImmediate,
    handleSaveConfig,
    handleCloseSnackbar,
    snackbarConfig,
    handleConfigDay,
    isLoadingConfig,
    isSavingConfig,
}: BillingDateConfigViewProps): JSX.Element {
    function getNextMonth() {
        if (new Date().getMonth() === 11) return 0
        return new Date().getMonth() + 1
    }
    return (
        <GlobalThemeProvider>
            <PageContainer>
                <ConfigContainer>
                    <ConfigLabel formLabel>
                        configuração de datas e prazos
                    </ConfigLabel>
                    <ConfigRow>
                        <ConfigItem>
                            <ConfigLabel>
                                Aceitar pedidos imediatos no mês CORRENTE (Mês
                                atual)
                            </ConfigLabel>
                            <SwitchButtonComponent
                                testid="acceptImmediateOrders-switch"
                                activeText="Sim"
                                inactiveText="Não"
                                active={billingDateConfig.acceptImmediateOrders}
                                onClick={(isActive) =>
                                    handleAcceptImmediate(isActive)
                                }
                            />
                            <ConfigDescription>
                                Habilita a possibilidade de{' '}
                                <strong>pedidos imediatos</strong> no
                                aplicativo, de forma que os pedidos enviados
                                pelos vendedores possam ser implantados ainda
                                dentro do mês, mesmo que estejam{' '}
                                <strong>
                                    fora do intervalo parametrizado para
                                    implantação.
                                </strong>
                            </ConfigDescription>
                        </ConfigItem>
                    </ConfigRow>
                    <ConfigLabel formLabel>
                        Configurar carregamento de pedidos por mês
                    </ConfigLabel>

                    {isLoadingConfig ? (
                        <>
                            <Skeleton
                                variant="rectangular"
                                height={200}
                                animation="wave"
                            />

                            <Skeleton
                                variant="rectangular"
                                width="80%"
                                height={60}
                                animation="wave"
                            />
                        </>
                    ) : (
                        <DateContainer>
                            <Header>
                                <RouContent>
                                    <RowItem>Mês</RowItem>
                                    <RowItem>
                                        Início intervalo de carregamento
                                    </RowItem>
                                    <RowItem>
                                        Final intervalo de carregamento
                                    </RowItem>
                                </RouContent>
                            </Header>
                            <Row>
                                <RouContent>
                                    <RowItem>Vigente (atual)</RowItem>
                                    <RowItem>
                                        <CustomDatePicker
                                            label="De"
                                            minDate={dayjs(new Date())}
                                            maxDate={dayjs().endOf('month')}
                                            defaultValue={dayjs(new Date(), {
                                                format: 'DD/MM/YYYY',
                                            }).set(
                                                'D',
                                                billingDateConfig.currentMonth
                                                    .start
                                            )}
                                            onChange={(value) => {
                                                handleConfigDay({
                                                    month: 'currentMonth',
                                                    period: 'start',
                                                    value,
                                                })
                                            }}
                                        />
                                    </RowItem>
                                    <RowItem>
                                        <CustomDatePicker
                                            label="Até"
                                            minDate={dayjs(new Date())}
                                            maxDate={dayjs().endOf('month')}
                                            defaultValue={dayjs(new Date(), {
                                                format: 'DD/MM/YYYY',
                                            }).set(
                                                'D',
                                                billingDateConfig.currentMonth
                                                    .end
                                            )}
                                            onChange={(value) => {
                                                handleConfigDay({
                                                    month: 'currentMonth',
                                                    period: 'end',
                                                    value,
                                                })
                                            }}
                                        />
                                    </RowItem>
                                </RouContent>
                            </Row>
                            <Row>
                                <RouContent>
                                    <RowItem>Próximo mês</RowItem>
                                    <RowItem>
                                        <CustomDatePicker
                                            label="De"
                                            minDate={dayjs()
                                                .set('M', getNextMonth())
                                                .startOf('month')}
                                            maxDate={dayjs()
                                                .set('M', getNextMonth())
                                                .endOf('month')}
                                            defaultValue={dayjs(new Date(), {
                                                format: 'DD/MM/YYYY',
                                            })
                                                .set('M', getNextMonth())
                                                .set(
                                                    'D',
                                                    billingDateConfig.nextMonths
                                                        .start
                                                )}
                                            onChange={(value) => {
                                                handleConfigDay({
                                                    month: 'nextMonths',
                                                    period: 'start',
                                                    value,
                                                })
                                            }}
                                        />
                                    </RowItem>
                                    <RowItem>
                                        <CustomDatePicker
                                            label="Até"
                                            minDate={dayjs()
                                                .set('M', getNextMonth())
                                                .startOf('month')}
                                            maxDate={dayjs()
                                                .set('M', getNextMonth())
                                                .endOf('month')}
                                            defaultValue={dayjs(new Date(), {
                                                format: 'DD/MM/YYYY',
                                            })
                                                .set('M', getNextMonth())
                                                .set(
                                                    'D',
                                                    billingDateConfig.nextMonths
                                                        .end
                                                )}
                                            onChange={(value) => {
                                                handleConfigDay({
                                                    month: 'nextMonths',
                                                    period: 'end',
                                                    value,
                                                })
                                            }}
                                        />
                                    </RowItem>
                                </RouContent>
                            </Row>
                            <InfoContainer>
                                <ConfigDescription>
                                    Caso haja intervalos sem parametrização de
                                    datas, dentro dos meses, estes serão
                                    tratados como pedidos imediatos. Pedidos
                                    para meses fora do mês corrente são tratados
                                    como pedidos programados. Caso o dia 15 do
                                    mês caia em um feriado nacional ou fim de
                                    semana, o dia útil antecedente será levado
                                    em conta como prazo final para o
                                    carregamento. Fins de semana e feriados
                                    nacionais são desconsiderados dentro do
                                    intervalo paramentrizado.
                                </ConfigDescription>
                            </InfoContainer>
                        </DateContainer>
                    )}
                </ConfigContainer>

                <Footer>
                    <WarningText>
                        *Salve as informações após as alterações realizadas. O
                        não salvamento implicará na perda das mesmas.
                    </WarningText>
                    <FormButtonsContainer>
                        <Link to="/">
                            <CancelButton variant="outlined" disabled={false}>
                                Cancelar
                            </CancelButton>
                        </Link>
                        <SaveButton
                            startIcon={
                                isSavingConfig && (
                                    <LoadingComponent size="small" />
                                )
                            }
                            onClick={handleSaveConfig}
                            disabled={isSavingConfig}
                            endIcon={<CheckIcon />}
                        >
                            Salvar
                        </SaveButton>
                    </FormButtonsContainer>
                </Footer>

                <SnackBarComponent
                    handleClose={handleCloseSnackbar}
                    isVisible={snackbarConfig.isVisible}
                    message={snackbarConfig.message}
                    severity={snackbarConfig.status}
                />
            </PageContainer>
        </GlobalThemeProvider>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.secondaryWhite};
`

const ConfigContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    margin: 11px 32px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 3px;
    padding: 28px 23px 23px 22px;
    gap: 16px;
    background-color: ${COLORS.primaryWhite};
`
const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const ConfigItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;
    padding: 15px 23px 26px;
    border-radius: 3px;
    border: solid 1px ${COLORS.borderGray};
`

const ConfigRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px ${COLORS.borderGray};
    background-color: ${COLORS.primaryWhite};
`
const InfoContainer = styled.div`
    width: 80%;
    padding: 15px 0px 26px;
`
const RowItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 33%;
    justify-content: center;

    align-items: center;
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    row-gap: 16px;
    border-radius: 3px;

    justify-content: center;

    background-color: ${COLORS.borderGray};
`

const RouContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 70%;
    padding: 10px 0px 10px;
    font-size: 14px;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    justify-content: space-between;
    color: ${COLORS.primaryGray};
`

const ConfigLabel = styled.span<{
    formLabel?: boolean
}>`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
    text-transform: uppercase;
    ${({ formLabel }) =>
        formLabel &&
        `
        margin: 0px 0px 2px;
    `}
`

const ConfigDescription = styled.span`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
`

const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 250px;
`

const Footer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    padding: 0 32px 30px;
    margin-top: 9px;
`

const SaveButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    backgroundColor: COLORS.primaryGreen,
    borderColor: COLORS.primaryGreen,
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    '&:hover': {
        backgroundColor: COLORS.secondaryGreen,
        borderColor: COLORS.secondaryGreen,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
})

const WarningText = styled.span`
    color: ${COLORS.primaryGray};
    font-size: 12px;
`
