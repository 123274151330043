import { IFeature } from '../../models/IFeature'
import { IVersion } from '../../models/IVersion'
import { isDraftFeature, isFeatureWithVersion } from './FeatureUtils'

export const handleFeatures = (
    features: IFeature[],
    selectedSource: string,
    selectedVersion: IVersion
): IFeature[] | [] => {
    const availableFeatures = features.filter((feature: IFeature) =>
        isFeatureAvailable(feature)
    )

    if (selectedSource) {
        const alreadyRelatedFeatures = features.filter(
            (feature) =>
                feature.versionId === selectedVersion.id &&
                feature.sourceId === selectedSource
        )

        const featuresForSelectedSource = availableFeatures.filter(
            (feature: IFeature) => feature.sourceId === selectedSource
        )

        return [...featuresForSelectedSource, ...alreadyRelatedFeatures]
    }

    return availableFeatures
}

export const isFeatureAvailable = (feature: IFeature): boolean =>
    !isDraftFeature(feature) && !isFeatureWithVersion(feature)
