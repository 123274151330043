import { useEffect, useState } from 'react'

import { makeLocalStorageService } from '../../../commons/utils/LocalStorageService'
import { AvailableItem } from '../../../models/AvailableItem'
import { StorageKeys } from '../../../models/ILocalStorageService'
import { ParameterOption } from '../../../models/ParameterOption'
import {
    Configuration,
    PermissionConfig,
} from '../../../models/PermissionConfig'
import { UserToken } from '../../../models/UserToken'
import {
    getPaymentFormSalesChannelsConfig,
    upsertPaymentFormSalesChannelsConfig,
} from '../../AppPermissionsAPI'
import CustomConfigClientProfile from '../components/CustomConfigClientProfile'
import {
    formatConfigItems,
    formatNewConfigList,
} from '../utils/ConfigListFormaters'

type PaymentTermsTabProps = {
    parameters: ParameterOption[]
    availablePaymentTerms: AvailableItem[]
}

export default function PaymentTermsTab({
    parameters,
    availablePaymentTerms,
}: PaymentTermsTabProps) {
    const [configList, setConfigList] = useState<Configuration[]>([])
    const [configItems, setConfigItems] = useState<PermissionConfig[]>([])
    const [initialConfigItems, setInitialConfigItems] = useState<
        PermissionConfig[]
    >([])
    const [selectedParameterValue, setSelectedParameterValue] =
        useState<string>('all')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [configLoadingFailed, setConfigLoadingFailed] =
        useState<boolean>(false)

    const { getItem } = makeLocalStorageService()
    const user = getItem(StorageKeys.USERTOKEN) as UserToken

    useEffect(() => {
        if (configList?.length > 0 && selectedParameterValue) {
            const newConfigItems = formatConfigItems(
                selectedParameterValue,
                configList
            )
            setInitialConfigItems(newConfigItems)
            setConfigItems(newConfigItems)
        }
    }, [selectedParameterValue, configList])

    const loadConfigList = async () => {
        try {
            setIsLoading(true)
            const {
                data: { config },
            } = await getPaymentFormSalesChannelsConfig(user.accessToken.token)
            setConfigList(config)
        } catch (error) {
            console.error(
                `Erro ao carregar condições de pagamento cadastradas para o setor industrial. ${error}`
            )
            setConfigLoadingFailed(true)
            setConfigList([])
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        loadConfigList()
    }, [])

    const handleSave = async () => {
        if (!selectedParameterValue) return

        const body = formatNewConfigList(
            selectedParameterValue,
            configList,
            configItems
        )
        try {
            await upsertPaymentFormSalesChannelsConfig(
                user.accessToken.token,
                body
            )
        } catch (error) {
            console.error(
                `Erro ao salvar condições de pagamento por setor industrial. ${error}`
            )
            throw error
        } finally {
            loadConfigList()
        }
    }

    const handleCancel = (configs: PermissionConfig[]) => {
        setConfigItems(configs)
    }

    return (
        <CustomConfigClientProfile
            availableItems={availablePaymentTerms}
            configItems={configItems}
            initialConfigItems={initialConfigItems}
            isLoading={isLoading}
            parameters={parameters}
            parameterType="paymentTerms"
            configLoadingFailed={configLoadingFailed}
            onCancel={handleCancel}
            onChangeConfigItems={setConfigItems}
            onChangeSelectedParameterValue={setSelectedParameterValue}
            onSave={handleSave}
        />
    )
}
