import React from 'react'

const MillIcon = () => {
    return (
        <svg width="14" height="13" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd" fill="none">
                <path
                    d="M13.025 12.163h.552a.42.42 0 0 1 .423.419.42.42 0 0 1-.422.418H.423A.42.42 0 0 1 0 12.582a.42.42 0 0 1 .423-.42h.552V5.106a.42.42 0 0 1 .423-.418h5.905V.419A.42.42 0 0 1 7.725 0h1.162a.42.42 0 0 1 .422.419v4.268h.372V.419A.42.42 0 0 1 10.103 0h1.162a.42.42 0 0 1 .423.419v4.268h.914a.42.42 0 0 1 .423.418v7.058zM3.318 9.084v3.079h2.87V9.084h-2.87z"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
}

export default MillIcon
