import {
    FormControl,
    InputBaseComponentProps,
    OutlinedInput,
    OutlinedInputProps,
    SelectChangeEvent,
    SelectProps,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import { styled as materialStyled } from '@mui/material/styles'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { COLORS } from '../Colors'

export type FormInputComponentProps = {
    label: string
    width?: number
    startAdornment?: React.ReactNode
    placeholder?: string
    testid?: string
    value?: unknown
    onChange?:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | ((event: SelectChangeEvent<unknown>, child: ReactNode) => void)
    suffix?: string
    required?: boolean
    select?: boolean
    selectOptions?: string[]
    inputProps?: InputBaseComponentProps
}

function FormInputComponent({
    label,
    width = 194,
    startAdornment,
    placeholder,
    testid,
    value,
    onChange,
    suffix,
    required,
    select = false,
    selectOptions,
    inputProps,
}: FormInputComponentProps): JSX.Element {
    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <FormInputContainer>
                {select ? (
                    <FormSelect
                        width={width}
                        startAdornment={startAdornment}
                        placeholder={placeholder}
                        data-testid={testid}
                        value={value || 'none'}
                        empty={!value}
                        onChange={
                            onChange as
                                | ((
                                      event: SelectChangeEvent<unknown>,
                                      child: ReactNode
                                  ) => void)
                                | undefined
                        }
                        required={required}
                        variant="outlined"
                    >
                        <MenuItem
                            value="none"
                            disabled
                            sx={{ display: 'none' }}
                        >
                            {placeholder}
                        </MenuItem>
                        {selectOptions?.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </FormSelect>
                ) : (
                    <FormInput
                        width={width}
                        startAdornment={startAdornment}
                        placeholder={placeholder}
                        data-testid={testid}
                        value={value}
                        onChange={
                            onChange as
                                | React.ChangeEventHandler<
                                      HTMLTextAreaElement | HTMLInputElement
                                  >
                                | undefined
                        }
                        required={required}
                        inputProps={inputProps}
                    />
                )}
                {suffix && <InputLabel>{suffix}</InputLabel>}
            </FormInputContainer>
        </FormControl>
    )
}

const FormInput = materialStyled(OutlinedInput)<
    OutlinedInputProps & { width: number }
>((props) => ({
    width: props.width,
    height: 40,
    '& .MuiInputBase-root': {
        borderRadius: 4,
        border: `solid 1px ${COLORS.disabledButton}`,
    },
    '& .MuiInputBase-input': {
        flexGrow: 0,
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.25,
        textAlign: 'left',
        color: COLORS.mainHeaderText,
    },
    '& .MuiInputBase-input::placeholder': {
        flexGrow: 0,
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.25,
        textAlign: 'left',
        color: COLORS.secondaryGray,
        opacity: 1,
    },
}))

const FormSelect = materialStyled(MuiSelect)<
    SelectProps & { width: number; empty?: boolean }
>((props) => ({
    width: props.width,
    height: 40,
    '& .MuiInputBase-root': {
        borderRadius: 4,
        border: `solid 1px ${COLORS.disabledButton}`,
    },
    '& .MuiInputBase-input': {
        flexGrow: 0,
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.25,
        textAlign: 'left',
        color: props.empty ? COLORS.secondaryGray : COLORS.mainHeaderText,
    },
}))

const FormInputContainer = styled.div`
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
`

const InputLabel = styled.span`
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${COLORS.primaryGray};
    text-transform: uppercase;
`

export default FormInputComponent
