import { getAPIKey, getBaseURL, getOrigin } from '../environment'
import { BillingDateConfig } from '../models/BillingDateConfig'
import { Configuration } from '../models/PermissionConfig'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const origin = getOrigin()

export const getBilingDateConfig = async (): Promise<{
    data: BillingDateConfig
}> => {
    return fetch(`${baseURL}/orders/billingDateConfig`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const setBilingDateConfig = async (
    config: BillingDateConfig
): Promise<{ data: BillingDateConfig }> => {
    return fetch(`${baseURL}/orders/billingDateConfig`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(config),
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getConfigList = async (
    url: string
): Promise<{ data: { config: Configuration[] } }> => {
    return fetch(`${baseURL}/${url}`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const saveConfigList = async (
    url: string,
    config: Configuration[]
): Promise<{ message: string }> => {
    return fetch(`${baseURL}/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(config),
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getProducts = async (
    millId: string,
    token: string,
    userId: string
) => {
    return fetch(`${baseURL}/products/products?millId=${millId}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            userId,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getParameterOptions = async (token: string) => {
    return fetch(`${baseURL}/sapGroupingConfig`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getPaymentForms = async (
    millId: string,
    token: string,
    userId: string
) => {
    return fetch(`${baseURL}/orders/paymentForms?millId=${millId}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            userId,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getPaymentFormSalesChannelsConfig = async (
    token: string
): Promise<{ data: { config: Configuration[] } }> => {
    return fetch(`${baseURL}/orders/paymentForms/salesChannelsConfig`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const upsertPaymentFormSalesChannelsConfig = async (
    token: string,
    config: Configuration[]
): Promise<{ message: string }> => {
    return fetch(`${baseURL}/orders/paymentForms/salesChannelsConfig`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(config),
    })
        .then((response: Response) => {
            const responseJson = response.json()
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson))
            }
            return responseJson
        })
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
