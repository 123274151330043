import { useState, useEffect } from 'react'
import styled from 'styled-components'

import LoadingComponent from '../commons/components/LoadingComponent'
import ModalComponent from '../commons/components/ModalComponent'
import RoundButtonComponent from '../commons/components/RoundButtonComponent'
import TextInputComponent from '../commons/components/TextInputComponent'
import { IFeature } from '../models/IFeature'
import { ISource } from '../models/ISource'
import { getAllSources } from './API/SourceAPI'
import SourceOptionsListComponent from './SourceOptionsListComponent'
import { MountedSource, mountSourceOptions } from './utils/SourceUtils'

export type FeatureCreationModalComponentProps = {
    isVisible: boolean
    selectedFeature: IFeature | undefined
    setIsVisible: (isVisible: boolean) => void | null
    onSave: (item: IFeature) => Promise<IFeature | void> | void
}

function FeatureCreationModalComponent({
    isVisible,
    selectedFeature,
    setIsVisible,
    onSave,
}: FeatureCreationModalComponentProps): JSX.Element {
    type NewFeatureTypes = {
        [objectKey: string]: string
        name: string
        description: string
        sourceId: string
        fileURL: string
    }

    const modalInitalValue: NewFeatureTypes = {
        name: '',
        description: '',
        sourceId: '',
        fileURL: '',
    }

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [newFeature, setNewFeature] =
        useState<NewFeatureTypes>(modalInitalValue)
    const [sources, setSources] = useState<MountedSource[]>([])

    useEffect(() => {
        const fetchSources = async () => {
            const response: { data: ISource[] } = await getAllSources()
            if (response.data) {
                const mountedSources: MountedSource[] = mountSourceOptions(
                    response.data
                )
                setIsLoading(false)
                setSources(mountedSources)
            }
        }

        try {
            fetchSources()
        } catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        if (selectedFeature) {
            const featureToEdit: NewFeatureTypes = {
                name: selectedFeature.name,
                sourceId: selectedFeature?.sourceId || '',
                fileURL: selectedFeature?.fileURL || '',
                description: selectedFeature?.description || '',
                id: selectedFeature?.id || '',
            }

            setNewFeature(featureToEdit)
        }
    }, [selectedFeature])

    const handleChange = (value: string, name: string) => {
        setNewFeature((oldValue: NewFeatureTypes) => ({
            ...oldValue,
            [name]: value,
        }))
    }

    const handleOnSave = async () => {
        setNewFeature(modalInitalValue)
        setIsVisible(false)

        await onSave(newFeature)
    }

    const handleOnClose = () => {
        setIsVisible(false)
        setNewFeature(modalInitalValue)
    }

    const getValue = (objectKey: string) => newFeature[objectKey]

    const isSaveDisabled = () =>
        Boolean(!newFeature.name || !newFeature.sourceId)

    if (isLoading) {
        return (
            <ModalComponent
                onClose={handleOnClose}
                isVisible={isVisible}
                title="Nova Feature"
            >
                <LoadingComponentConatiner>
                    <LoadingComponent />
                </LoadingComponentConatiner>
            </ModalComponent>
        )
    }

    return (
        <ModalComponent
            onClose={handleOnClose}
            isVisible={isVisible}
            title="Nova Feature"
        >
            <InputsContainer>
                <NameInputContainer>
                    <TextInputComponent
                        labelText="*NOME"
                        name="name"
                        onChange={handleChange}
                        getValue={getValue}
                    />
                </NameInputContainer>
                <RadioOptionsContainer>
                    <SourceOptionsListComponent
                        inputTitle="*LOCAL"
                        inputName="sourceId"
                        options={sources}
                        onChange={handleChange}
                        selectedValue={newFeature?.sourceId || ''}
                    />
                </RadioOptionsContainer>
                <DescriptionContainer>
                    <TextInputComponent
                        labelText="DESCRIÇÃO"
                        name="description"
                        onChange={handleChange}
                        getValue={getValue}
                        isTextArea
                    />
                </DescriptionContainer>

                <DocumentUrlContainer>
                    <TextInputComponent
                        labelText="LINK DO ARQUIVO EM PDF"
                        name="fileURL"
                        onChange={handleChange}
                        getValue={getValue}
                    />
                </DocumentUrlContainer>
            </InputsContainer>

            <SaveButtonContainer>
                <RoundButtonComponent
                    text="Salvar"
                    onClick={handleOnSave}
                    disabled={isSaveDisabled()}
                />
            </SaveButtonContainer>
        </ModalComponent>
    )
}

const LoadingComponentConatiner = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 230px;
    padding-bottom: 230px;
`

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 23px 35px 23px;
`

const NameInputContainer = styled.div`
    padding-top: 10px;
`

const RadioOptionsContainer = styled.div`
    padding-top: 23px;
`

const DescriptionContainer = styled.div`
    padding-top: 23px;
`

const DocumentUrlContainer = styled.div`
    padding-top: 23px;
`

const SaveButtonContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    padding-bottom: 20px;
`

export default FeatureCreationModalComponent
