export enum StorageKeys {
    TOKEN = 'NEWS-TOKEN',
    USERTOKEN = 'USER-TOKEN',
}

export interface ILocalStorageService {
    getItem: <T>(key: StorageKeys) => T
    setItem: (key: StorageKeys, item: any) => void
    removeItem: (key: StorageKeys) => void
}
