export type ImportError = {
    lineNumber: string
    field: string
    error: string
}

export type ImportForm = {
    mill: { id: string | null; name: string | null }
    importType: ImportOption
    importFile: any
}

export const importFormInitialValue = {
    mill: { name: null, id: null },
    importFile: null,
    importType: {} as unknown as ImportOption,
}

export type ImportValidation = {
    errors: ImportError[]
    isProcessing: boolean
    isDone: boolean
}

export const importValidationInitialValue: ImportValidation = {
    errors: [],
    isProcessing: false,
    isDone: false,
}

export type ImportConfirmation = {
    isModalVisible: boolean
    isProcessing: boolean
}

export const importConfirmationInitialValue: ImportConfirmation = {
    isModalVisible: false,
    isProcessing: false,
}

export type ImportOption = {
    label: string
    value:
        | 'Products'
        | 'WarrantyLevels'
        | 'paymentForms'
        | 'clients'
        | 'freights'
        | 'users'
    function: 'products' | 'imports'
    methods: {
        validate: string
        import: string
    }
}

export const IMPORT_OPTIONS: ImportOption[] = [
    {
        label: 'Produtos e Rótulos',
        value: 'Products',
        function: 'products',
        methods: {
            validate: 'validateProducts',
            import: 'importProducts',
        },
    },
    {
        label: 'Níveis de Garantia',
        value: 'WarrantyLevels',
        function: 'products',
        methods: {
            validate: 'validateWarrantyLevels',
            import: 'importWarrantyLevels',
        },
    },

    {
        label: 'Formas de Pagamento',
        value: 'paymentForms',
        function: 'imports',
        methods: {
            validate: 'validates',
            import: 'imports',
        },
    },

    {
        label: 'Fazendas',
        value: 'clients',
        function: 'imports',
        methods: {
            validate: 'validates',
            import: 'imports',
        },
    },

    {
        label: 'Fretes',
        value: 'freights',
        function: 'imports',
        methods: {
            validate: 'validates',
            import: 'imports',
        },
    },

    {
        label: 'Usuários',
        value: 'users',
        function: 'imports',
        methods: {
            validate: 'validates',
            import: 'imports',
        },
    },
]
