import { Pagination } from '@mui/material'

import GlobalThemeProvider from '../../style/GlobalTheme'

export const getPaginatedData = (
    data: any[],
    pagination: number,
    currentPage = 1
): any[] => {
    const finalIndex = currentPage * pagination
    const initialIndex = finalIndex - pagination

    return data.filter(
        (_data, index) => index >= initialIndex && index < finalIndex
    )
}

export type PaginationComponentProps = {
    numberOfPages: number
    currentPage: number
    onChange: (_event: React.ChangeEvent<unknown>, page: number) => void
}

function PaginationComponent({
    numberOfPages,
    currentPage,
    onChange,
}: PaginationComponentProps): JSX.Element {
    return (
        <GlobalThemeProvider>
            <Pagination
                count={Math.ceil(numberOfPages)}
                showLastButton
                showFirstButton
                onChange={onChange}
                size="small"
                page={currentPage || 1}
                color="primary"
            />
        </GlobalThemeProvider>
    )
}

export default PaginationComponent
