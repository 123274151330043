export type MillListHeaders = {
    label: 'Código' | 'Nome Fantasia' | 'Razão Social' | ''
    value: 'legacyCode' | 'name' | 'corporateName' | 'icons'
}

export const avaliableUfs = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
]

export type MillForm = {
    legacyCode: string
    name: string
    corporateName: string
    uf: string
}

export type ConfigForm = {
    allowsOrderEdition: boolean
    stockSafetyDays?: number
    allowsOrderOutOfPricePolicy: boolean
    chapaValue?: number
    usePallet: boolean
    useChapa: boolean
    allowsCustomDeadline: boolean
}

export enum TypeForm {
    Mill = 1,
    Config,
}
