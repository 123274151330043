function CloseCrossIcon(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
        >
            <path
                d="M15.042 5.969 13.925 4.86 9.5 9.253 5.074 4.86 3.958 5.969l4.426 4.392-4.426 4.392 1.116 1.108L9.5 11.469l4.425 4.392 1.117-1.108-4.426-4.392 4.426-4.392z"
                fill="currentColor"
            />
        </svg>
    )
}

export default CloseCrossIcon
