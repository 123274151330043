import styled from 'styled-components'

import LoadingComponent from '../commons/components/LoadingComponent'
import ModalComponent from '../commons/components/ModalComponent'
import RoundButtonComponent from '../commons/components/RoundButtonComponent'

type ImportConfirmationModalComponentProps = {
    isVisible: boolean
    isLoading: boolean
    importType: string
    millName: string
    fileName: string
    handleConfirmation: (isSendFile?: boolean) => Promise<void>
    onClose: () => void
}

function ImportConfirmationModalComponent({
    isVisible,
    isLoading,
    importType,
    millName,
    fileName,
    handleConfirmation,
    onClose,
}: ImportConfirmationModalComponentProps): JSX.Element {
    return (
        <ModalComponent
            title="Confirmar Importação"
            isVisible={isVisible}
            onClose={onClose}
        >
            {isLoading ? (
                <ModalContentContainer>
                    <ModalSubtitleContainer>
                        <SimpleText>
                            Importação do arquivo
                            <strong> {fileName} </strong> em processamento, por
                            favor aguarde. A operação poderá levar alguns
                            minutos.
                        </SimpleText>
                    </ModalSubtitleContainer>
                    <LoadingContainer>
                        <LoadingComponent size="medium" />
                    </LoadingContainer>
                    <ModalButtonContainer>
                        <RoundButtonComponent text="Confirmar" disabled />
                        <RoundButtonComponent text="Cancelar" disabled />
                    </ModalButtonContainer>
                </ModalContentContainer>
            ) : (
                <ModalContentContainer>
                    <ModalSubtitleContainer>
                        <SimpleText>
                            Deseja iniciar a importação de
                            <strong> {importType} </strong> para a fábrica
                            <strong> {millName}</strong> utilizando o arquivo{' '}
                            <strong>{fileName}</strong>? A operação não poderá
                            ser desfeita.
                        </SimpleText>
                    </ModalSubtitleContainer>
                    <ModalButtonContainer>
                        <RoundButtonComponent
                            text="Confirmar"
                            onClick={() => handleConfirmation(true)}
                        />
                        <RoundButtonComponent
                            text="Cancelar"
                            onClick={onClose}
                        />
                    </ModalButtonContainer>
                </ModalContentContainer>
            )}
        </ModalComponent>
    )
}

const ModalContentContainer = styled.div`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ModalSubtitleContainer = styled.div`
    width: 95%;
    display: flex;
`
const ModalButtonContainer = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-between;
    padding: 40px 0 15px 0;
`

const SimpleText = styled.span`
    font-family: Roboto;
    font-size: 14px;
`

const LoadingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0 0 0;
`

export default ImportConfirmationModalComponent
