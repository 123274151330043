import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import LoadingComponent from '../../commons/components/LoadingComponent'
import RoundButtonComponent from '../../commons/components/RoundButtonComponent'
import SearchInputComponent from '../../commons/components/SearchInputComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import { IFeature } from '../../models/IFeature'
import {
    getAllFeatures,
    createFeature,
    deleteFeature,
    updateFeature,
} from '../API/FeatureAPI'
import FeatureCardListComponent from '../FeatureCardListComponent'
import FeatureCreationModalComponent from '../FeatureCreationModalComponent'
import NotRegistredComponent from '../NotRegistredComponent'

function FeatureTabContentScreen(): JSX.Element {
    const [features, setFeatures] = useState<IFeature[]>([])
    const [searchedData, setSearchedData] = useState<IFeature[]>(features || [])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isNewFeatureModalVisible, setIsNewFeatureModalVisible] =
        useState<boolean>(false)
    const [selectedFeature, setSelectedFeature] = useState<
        IFeature | undefined
    >()
    const [snackBarConfig, setSnackBarConfig] = useState<{
        isVisible: boolean
        message: string
        status: 'success' | 'error' | 'warning' | 'info'
    }>({ message: '', isVisible: false, status: 'success' })

    const fetchFeatures = async () => {
        const response = await getAllFeatures()
        if (response.data) {
            setFeatures(response.data)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        try {
            setIsLoading(true)
            fetchFeatures()
        } catch (err) {
            console.error(err)
        }
    }, [])

    const handleOnEditFeature = (feature: IFeature) => {
        setSelectedFeature(feature)
        setIsNewFeatureModalVisible(true)
    }

    const handleOnCloseModal = () => {
        setSelectedFeature(undefined)
        setIsNewFeatureModalVisible(false)
    }

    const handleOnCloseSnackBar = () => {
        setSnackBarConfig({ message: '', isVisible: false, status: 'success' })
    }

    const handleOnSaveFeature = async (feature: IFeature): Promise<void> => {
        const result = selectedFeature?.id
            ? await updateFeature(feature)
            : await createFeature(feature)

        if (result.data) {
            setSnackBarConfig({
                message: `Feature ${
                    selectedFeature?.id ? 'editada' : 'criada'
                } com sucesso!`,
                isVisible: true,
                status: 'success',
            })

            try {
                setIsLoading(true)
                fetchFeatures()
            } catch (err) {
                console.error(err)
            }

            return
        }

        setSnackBarConfig({
            message: 'Erro ao criar feature!',
            isVisible: true,
            status: 'error',
        })
    }

    const handleOnDeleteFeature = async (featureId: string): Promise<void> => {
        const result = await deleteFeature(featureId)
        if (result.message === 'Feature deletada com sucesso.') {
            setSnackBarConfig({
                message: result.message,
                isVisible: true,
                status: 'success',
            })

            try {
                setIsLoading(true)
                fetchFeatures()
            } catch (err) {
                console.error(err)
            }
        }
    }

    if (isLoading)
        return (
            <FeatureTabContainer>
                <LoadingContainer>
                    <LoadingComponent />
                </LoadingContainer>
            </FeatureTabContainer>
        )

    return (
        <FeatureTabContainer>
            {!features.length ? (
                <NotRegistredComponent
                    title="feature"
                    onClick={() => setIsNewFeatureModalVisible(true)}
                />
            ) : (
                <TabContentContainer>
                    <TabHeaderContainer>
                        <FilterContainer>
                            <SearchInputComponent
                                label="Filtro"
                                placeholderText="Filtrar por tipo e descrição"
                                data={features}
                                filterKeys={['description', 'name']}
                                getSearchedData={setSearchedData}
                            />
                        </FilterContainer>
                        <NewFeatureButtonContainer>
                            <RoundButtonComponent
                                text="Nova Feature"
                                onClick={() =>
                                    setIsNewFeatureModalVisible(true)
                                }
                            />
                        </NewFeatureButtonContainer>
                    </TabHeaderContainer>
                    <CardsContainer>
                        <FeatureCardListComponent
                            features={searchedData}
                            onEdit={handleOnEditFeature}
                            onDelete={handleOnDeleteFeature}
                        />
                    </CardsContainer>
                </TabContentContainer>
            )}

            {isNewFeatureModalVisible && (
                <FeatureCreationModalComponent
                    isVisible={isNewFeatureModalVisible}
                    selectedFeature={selectedFeature}
                    setIsVisible={handleOnCloseModal}
                    onSave={handleOnSaveFeature}
                />
            )}
            {snackBarConfig.isVisible && (
                <SnackBarComponent
                    severity={snackBarConfig.status}
                    isVisible={snackBarConfig.isVisible}
                    showTimeInMs={3000}
                    handleClose={handleOnCloseSnackBar}
                    message={snackBarConfig.message}
                />
            )}
        </FeatureTabContainer>
    )
}

const LoadingContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    height: 100%;
`

const TabContentContainer = styled.div``

const TabHeaderContainer = styled.div`
    display: flex;
    width: 99%;
    justify-content: space-between;
`

const FilterContainer = styled.div`
    display: flex;
    width: 30%;
    padding: 25px 0 8px 22px;
`

const NewFeatureButtonContainer = styled.div`
    padding: 40px 26px 0 0;
`
const CardsContainer = styled.div``

const FeatureTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    height: calc(100% - 260px);
    width: calc(100% - 64px);
    bottom: 28px;
    left: 32px;
    background-color: ${COLORS.primaryWhite};
    border: 1px solid ${COLORS.borderGray};
    overflow: auto;
`

export default FeatureTabContentScreen
