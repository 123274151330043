import { useState } from 'react'
import styled from 'styled-components'

import PaginationComponent, {
    getPaginatedData,
} from '../commons/components/PaginationComponent'
import { IFeature } from '../models/IFeature'
import FeatureCardComponent from './FeatureCardComponent'

export type FeatureCardListComponentProps = {
    features: IFeature[]
    onEdit: (value?: any) => void | string
    onDelete: (value?: any) => Promise<void> | void
}
function FeatureCardListComponent({
    features,
    onEdit = (featureId?: string) => featureId,
    onDelete,
}: FeatureCardListComponentProps): JSX.Element {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const pagination = 4

    const handleOnDelete = (feature: IFeature): void => {
        onDelete(feature.id)
    }

    const handleOnChangePage = (
        _event: React.ChangeEvent<unknown>,
        page: number
    ): void => {
        setCurrentPage(page)
    }

    const renderPaginatedFeatures = (): JSX.Element[] => {
        const paginatedFeatures = getPaginatedData(
            features,
            pagination,
            currentPage
        )

        if (features.length && !paginatedFeatures.length) setCurrentPage(1)

        return paginatedFeatures.map((feature) => (
            <CardContainer key={feature.id}>
                <FeatureCardComponent
                    feature={feature}
                    onEdit={onEdit}
                    onDelete={handleOnDelete}
                />
            </CardContainer>
        ))
    }

    return (
        <ComponentContainer>
            <CardListContainer>{renderPaginatedFeatures()}</CardListContainer>
            <PaginationContainer>
                <PaginationComponent
                    numberOfPages={features.length / pagination}
                    onChange={handleOnChangePage}
                    currentPage={currentPage}
                />
            </PaginationContainer>
        </ComponentContainer>
    )
}

const ComponentContainer = styled.div``
const CardListContainer = styled.div``
const CardContainer = styled.div`
    padding: 13px 23px 8px 22px;
`
const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 32px 0 32px 0;
`

export default FeatureCardListComponent
