export enum ROUTES {
    HOME = '/',
    LOGIN = '/login',
    IMPORT = '/suporte/importacao',
    NEWS = '/gestao/lancamentos',
    MILLSLIST = '/gestao/fabrica',
    MILLSNEW = '/gestao/fabrica/novo',
    MILLEDIT = '/gestao/fabrica/:id',
    APPPERMISSIONS = '/app/permissions',
}
