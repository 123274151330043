import { useState, useEffect } from 'react'
import styled from 'styled-components'

import LoadingComponent from '../commons/components/LoadingComponent'
import ModalComponent from '../commons/components/ModalComponent'
import RoundButtonComponent from '../commons/components/RoundButtonComponent'
import TextInputComponent from '../commons/components/TextInputComponent'
import { IFeature } from '../models/IFeature'
import { ISource } from '../models/ISource'
import { IVersion } from '../models/IVersion'
import { getAllSources } from './API/SourceAPI'
import FeatureSelectorComponent from './FeatureSelectorComponent'
import SourceOptionsListComponent from './SourceOptionsListComponent'
import { MountedSource, mountSourceOptions } from './utils/SourceUtils'

export type VersionCreationModalComponentProps = {
    isVisible: boolean
    setIsVisible: (isVisible: boolean) => void | null
    onSave: (item: IVersion) => Promise<IVersion | void> | void
    selectedVersion: IVersion | undefined
}

export default function VersionCreationModalComponent({
    isVisible,
    setIsVisible,
    onSave,
    selectedVersion,
}: VersionCreationModalComponentProps): JSX.Element {
    type VersionTypes = {
        [objectKey: string]: string | Array<IFeature> | []
        name: string
        sourceId: string
        features: Array<IFeature> | []
    }

    const modalInitalValue: VersionTypes = {
        name: '',
        sourceId: '',
        features: [],
    }

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [version, setVersion] = useState<VersionTypes>(modalInitalValue)
    const [sources, setSources] = useState<MountedSource[]>([])

    useEffect(() => {
        let componentDidMount = true
        const fetchSources = async () => {
            const response: { data: ISource[] } = await getAllSources()

            if (response.data) {
                const mountedSources: MountedSource[] = mountSourceOptions(
                    response.data
                )
                setSources(mountedSources)
            }

            setIsLoading(false)
        }

        if (componentDidMount) {
            try {
                setIsLoading(true)
                fetchSources()
            } catch (err) {
                console.error(err)
            }
        }

        return () => {
            componentDidMount = false
        }
    }, [])

    useEffect(() => {
        if (selectedVersion) {
            const versionToEdit: VersionTypes = {
                name: selectedVersion.name,
                sourceId: selectedVersion.sourceId,
                features: selectedVersion.features,
                id: selectedVersion.id || '',
            }

            setVersion(versionToEdit)
        }
    }, [selectedVersion])

    const handleChange = (
        value: string | Array<IFeature>,
        name: string
    ): void => {
        if (name === 'sourceId') {
            setVersion((oldValue: any) => ({
                ...oldValue,
                [name]: value,
                features: [],
            }))

            return
        }

        setVersion((oldValue: VersionTypes) => ({
            ...oldValue,
            [name]: value,
        }))
    }

    const handleOnSave = async () => {
        setVersion(modalInitalValue)
        setIsVisible(false)

        await onSave(version)
    }

    const handleOnClose = () => {
        setIsVisible(false)
        setVersion(modalInitalValue)
    }

    const getValue = (objectKey: string) => version[objectKey]

    const isSaveDisabled = () =>
        Boolean(
            !version.name || !version.sourceId || version.features.length === 0
        )

    if (isLoading)
        return (
            <ModalComponent
                onClose={handleOnClose}
                isVisible={isVisible}
                title="Nova Feature"
            >
                <LoadingComponentConatiner>
                    <LoadingComponent />
                </LoadingComponentConatiner>
            </ModalComponent>
        )

    return (
        <ModalComponent
            onClose={handleOnClose}
            isVisible={isVisible}
            title="Nova Versão"
        >
            <InputsContainer>
                <NameInputContainer>
                    <TextInputComponent
                        labelText="*NÚMERO"
                        name="name"
                        placeholder="Digite o número da versão"
                        onChange={handleChange}
                        getValue={getValue}
                    />
                </NameInputContainer>
                <RadioOptionsContainer>
                    <SourceOptionsListComponent
                        inputTitle="*LOCAL"
                        inputName="sourceId"
                        options={sources}
                        onChange={handleChange}
                        selectedValue={version?.sourceId || ''}
                    />
                </RadioOptionsContainer>
                <FeatureSelectorComponent
                    onChange={handleChange}
                    getValue={getValue}
                    selectedSource={version?.sourceId || ''}
                    selectedVersion={version}
                />
            </InputsContainer>

            <SaveButtonContainer>
                <RoundButtonComponent
                    text="Salvar"
                    onClick={handleOnSave}
                    disabled={isSaveDisabled()}
                />
            </SaveButtonContainer>
        </ModalComponent>
    )
}

const LoadingComponentConatiner = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 230px;
    padding-bottom: 230px;
`

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 23px 35px 23px;
`

const NameInputContainer = styled.div`
    padding-top: 10px;
`

const RadioOptionsContainer = styled.div`
    padding-top: 23px;
`

const SaveButtonContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    padding-bottom: 20px;
`
