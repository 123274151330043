import styled from 'styled-components'

import { COLORS } from '../../commons/Colors'
import BasicDropdownComponent from '../../commons/components/BasicDropdownComponent'
import LoadingComponent from '../../commons/components/LoadingComponent'
import MainHeaderComponent from '../../commons/components/MainHeaderComponent'
import RoundButtonComponent from '../../commons/components/RoundButtonComponent'
import SnackBarComponent from '../../commons/components/SnackBarComponent'
import { SnackbarConfigTypes } from '../../commons/utils/Types'
import ImportConfirmationModalComponent from '../ImportConfirmationModalComponent'
import {
    IMPORT_OPTIONS,
    ImportConfirmation,
    ImportForm,
    ImportValidation,
} from '../utils'

type ImportViewProps = {
    isLoading: boolean
    isConfirmEnabled: boolean
    isFileUploadEnabled: boolean
    isValidationError: boolean
    importForm: ImportForm
    handleForm: (
        field: keyof ImportForm,
        value: any,
        label?: string
    ) => Promise<void>
    importValidation: ImportValidation
    importConfirmation: ImportConfirmation
    handleImportConfirmation: (isSendFile?: boolean) => Promise<void>
    handleCloseConfirmationModal: () => void
    snackbarConfig: SnackbarConfigTypes
    handleCloseSnackbar: () => void
    mills: { label: string; value: string }[]
}

function ImportView({
    isLoading,
    isConfirmEnabled,
    isFileUploadEnabled,
    isValidationError,
    importForm,
    handleForm,
    importValidation,
    importConfirmation,
    handleImportConfirmation,
    handleCloseConfirmationModal,
    snackbarConfig,
    handleCloseSnackbar,
    mills,
}: ImportViewProps): JSX.Element {
    function renderImportErrorComponent() {
        return (
            <ImportErrorTable>
                <ImportErrorHeader>
                    <HeaderText>LINHA</HeaderText>
                    <HeaderText>CAMPO</HeaderText>
                    <HeaderText>ERRO</HeaderText>
                </ImportErrorHeader>
                {importValidation.errors.map((importError) => (
                    <ImportErrorRow key={`${importError.lineNumber}`}>
                        <RowText>{importError.lineNumber}</RowText>
                        <RowText>{importError.field}</RowText>
                        <ErrorText>{importError.error}</ErrorText>
                    </ImportErrorRow>
                ))}
            </ImportErrorTable>
        )
    }

    function renderImportDetailsSubtitle() {
        const successMessage = (
            <LabelSubtitle>
                O arquivo {`"`}
                <strong>{importForm.importFile?.name}</strong>
                {`"`} está pronto para ser importado!
            </LabelSubtitle>
        )
        const errorMessage = (
            <LabelSubtitle>
                Foram encontrados: {importValidation.errors.length} erros no
                arquivo:
                {`"`}
                <strong>{importForm.importFile?.name}</strong>
                {`"`}.
            </LabelSubtitle>
        )
        return isValidationError ? errorMessage : successMessage
    }

    return (
        <PageContainer>
            <MainHeaderComponent />
            <PageTitle>Importação de Dados</PageTitle>
            {isLoading ? (
                <LoadingContainer>
                    <LoadingComponent />
                </LoadingContainer>
            ) : (
                <ContentContainer>
                    <ContentRow>
                        <DropdownContainer>
                            <BasicDropdownComponent
                                options={mills}
                                onSelect={(option) =>
                                    handleForm(
                                        'mill',
                                        option.value,
                                        option.label
                                    )
                                }
                                placeholder="Fábrica"
                                value={{
                                    value: importForm.mill.id!,
                                    label: importForm.mill.name!,
                                }}
                                disabled={
                                    importValidation.isProcessing ||
                                    importConfirmation.isProcessing
                                }
                            />
                        </DropdownContainer>
                        <DropdownContainer>
                            <BasicDropdownComponent
                                options={IMPORT_OPTIONS}
                                onSelect={(option) =>
                                    handleForm(
                                        'importType',
                                        option,
                                        option.label
                                    )
                                }
                                placeholder="Tipo de Importação"
                                value={{
                                    value: importForm.importType.value,
                                    label: importForm.importType.label,
                                }}
                                disabled={
                                    importValidation.isProcessing ||
                                    importConfirmation.isProcessing
                                }
                            />
                        </DropdownContainer>
                    </ContentRow>

                    <ImportErrorContentContainer>
                        <ImportErrorLabelContainer>
                            <LabelText>Detalhes da Importação</LabelText>
                            {importValidation.isDone &&
                                renderImportDetailsSubtitle()}
                        </ImportErrorLabelContainer>
                        {isValidationError && renderImportErrorComponent()}
                    </ImportErrorContentContainer>

                    <ContentButtonRow>
                        <LeftButtonContainer>
                            <RoundButtonComponent
                                text="Selecione o Arquivo"
                                fileUpload
                                onClick={(file) =>
                                    handleForm('importFile', file)
                                }
                                disabled={!isFileUploadEnabled}
                                isLoading={importValidation.isProcessing}
                            />
                        </LeftButtonContainer>
                        <RightButtonContainer>
                            <RoundButtonComponent
                                text="Confirmar Importação"
                                onClick={() => handleImportConfirmation()}
                                disabled={!isConfirmEnabled}
                            />
                        </RightButtonContainer>
                    </ContentButtonRow>
                </ContentContainer>
            )}

            <ImportConfirmationModalComponent
                isLoading={importConfirmation.isProcessing}
                isVisible={importConfirmation.isModalVisible}
                onClose={handleCloseConfirmationModal}
                handleConfirmation={handleImportConfirmation}
                importType={importForm.importType.label}
                millName={importForm.mill.name!}
                fileName={importForm.importFile?.name}
            />

            <SnackBarComponent
                handleClose={handleCloseSnackbar}
                isVisible={snackbarConfig.isVisible}
                message={snackbarConfig.message}
                severity={snackbarConfig.status}
            />
        </PageContainer>
    )
}

const LoadingContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    height: 100%;
    padding-top: 10vw;
`

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`

const PageTitle = styled.h1`
    color: ${COLORS.primaryOrange};
    font-size: 18px;
    font-family: Roboto;
    font-weight: normal;
    padding: 3vw;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
`

const ContentRow = styled.div`
    padding: 15px 0 15px 0;
    display: flex;
    justify-content: space-between;
`

const DropdownContainer = styled.div`
    width: 48%;
`

const ContentButtonRow = styled(ContentRow)`
    justify-content: flex-end;
    width: 100%;
    align-self: flex-end;
`
const LeftButtonContainer = styled.div`
    padding-right: 12px;
`

const RightButtonContainer = styled.div`
    padding-left: 12px;
`

const ImportErrorContentContainer = styled(ContentRow)`
    border: 1px solid ${COLORS.disabledButton};
    border-radius: 5px;
    flex-direction: column;
`

const ImportErrorLabelContainer = styled.div`
    padding: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
`

const ImportErrorTable = styled.div`
    max-height: 230px;
    overflow: auto;
    width: 100%;
    border-top: 1px solid ${COLORS.disabledButton};
`

const ImportErrorHeader = styled.div`
    display: grid;
    grid-template-columns: 20% 20% 60%;
    padding: 15px 0 10px 15px;
    border-bottom: 1px solid ${COLORS.disabledButton};
`

const ImportErrorRow = styled(ImportErrorHeader)``

const RowText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    color: ${COLORS.mainHeaderText};
`
const ErrorText = styled(RowText)`
    color: ${COLORS.error};
`

const HeaderText = styled(RowText)`
    color: ${COLORS.mainHeaderText};
    font-weight: bold;
`

const LabelText = styled(RowText)`
    font-size: 16px;
    color: ${COLORS.primaryOrange};
`
const LabelSubtitle = styled(RowText)`
    padding: 5px 0 0 0;
`

export default ImportView
