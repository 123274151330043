import { getBaseURL, getAPIKey, getOrigin } from '../../environment'
import { IVersion } from '../../models/IVersion'

const baseURL = getBaseURL()
const APIKey = getAPIKey()
const origin = getOrigin()
const endpoint = '/news/versions'

export const getAllVersions = async (): Promise<{ data: Array<IVersion> }> => {
    return fetch(`${baseURL}${endpoint}?deleted=false`, {
        method: 'GET',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createVersion = async (
    version: IVersion
): Promise<{ data: IVersion }> => {
    return fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(version),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const updateVersion = async (
    version: IVersion
): Promise<{ data: IVersion }> => {
    return fetch(`${baseURL}${endpoint}/${version.id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': APIKey,
            'x-origin': origin,
        },
        body: JSON.stringify(version),
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const deleteVersion = async (
    versionId: string
): Promise<{ message: string }> => {
    return fetch(`${baseURL}${endpoint}/${versionId}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': APIKey,
            'x-origin': origin,
        },
    })
        .then((response: Response) => response.json())
        .catch((err) => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const publishVersions = (
    versions: Array<IVersion>
): Array<Promise<{ data: IVersion; message?: string }>> => {
    const versionsToPublish = versions.map((version) => ({
        ...version,
        published: true,
        releaseDate: new Date(),
    }))

    // TODO: Adicionar lógica de publicação no backend
    return versionsToPublish.map((version) => updateVersion(version))
}

export const cancelPublish = (
    version: IVersion
): Promise<{ data: IVersion; message?: string }> => {
    const verstionToUnpublish = {
        ...version,
        published: false,
        releaseDate: undefined,
    }

    return updateVersion(verstionToUnpublish)
}
